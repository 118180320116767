
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Root",

  computed: {
    layout() {
      const route = useRoute();

      if (route.meta.context == "auth") {
        return "empty-layout";
      }

      return `${route.meta.layout || "normal"}-layout`;
    },
  },

  async mounted() {
    console.log("Root.mounted");

    const store = useStore();

    store.dispatch("refreshUser");
  },
});
