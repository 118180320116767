import axios from "axios";
const KEY = "pLffrf8ROt3CFMYTQljV6gjzdPAt9rk0NMpSFiF";

export default class AddressUtil {
  static async search(postcode: string): Promise<any> {
    const match = postcode.match(/^[0-9]{3}-?[0-9]{4}$/);
    if (!match) {
      return null;
    }

    const config = {
      headers: {
        apikey: KEY,
      },
    };

    const response = await axios.get(
      `https://apis.postcode-jp.com/api/v5/postcodes/${postcode.replaceAll(
        "-",
        ""
      )}`,
      config
    );

    return response.data;
  }
}
