
import { defineComponent } from "vue";

import Salon from "@/models/Salon";
import Shop from "@/models/Shop";
import Staff from "@/models/Staff";
import Administrator from "@/models/Administrator";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

interface SalonDetailState {
  // is_loading: boolean;

  salon: Salon;
  shops: Shop[];
  staffs: Staff[];
  administrators: Administrator[];

  selected_tab: string;

  is_confirming: boolean;
  deletion_shop: Shop | null;
  deletion_administrator: Administrator | null;
}

export default defineComponent({
  components: {
    ConfirmDialog,
  },

  data() {
    return {
      // is_loading: false,

      salon: {} as Salon,
      shops: [] as Shop[],
      staffs: [] as Staff[],
      administrators: [] as Administrator[],

      selected_tab: "shops",

      is_confirming: false,
      deletion_shop: null,
      deletion_administrator: null,
    } as SalonDetailState;
  },

  computed: {
    dialog_title(): string {
      if (this.deletion_shop) {
        return "店舗の削除";
      }
      if (this.deletion_administrator) {
        return "管理者の削除";
      }

      return "";
    },

    dialog_message(): string {
      if (this.deletion_shop) {
        return `${this.deletion_shop.name}を削除しますが、よろしいですか？`;
      }
      if (this.deletion_administrator) {
        return `${this.deletion_administrator.name}を削除しますが、よろしいですか？`;
      }
      return "";
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatAccountType(type: string) {
      switch (type) {
        case "normal":
          return "普通";
        case "checking":
          return "当座";
        case "deposit":
          return "貯蓄";
        default:
          return "";
      }
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      const id = this.$route.params.salon_id as string;

      setTimeout(async () => {
        try {
          this.salon = await Salon.get(id);
          console.log(this.salon);
          const result1 = await Shop.search(id);
          this.shops = result1.shops;
          console.log(this.shops);

          const result2 = await Staff.searchBySalonId(id);
          this.staffs = result2.staffs;
          console.log(this.shops);

          const result3 = await Administrator.searchBySalonId(id);
          this.administrators = result3;
          console.log(this.shops);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onConfirmDeleteShop(shop: Shop) {
      this.deletion_shop = shop;
      this.is_confirming = true;
    },

    onConfirmDeleteAdministrator(administartor: Administrator) {
      this.deletion_administrator = administartor;
      this.is_confirming = true;
    },

    onDelete() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        if (this.deletion_shop) {
          try {
            await Shop.delete(this.deletion_shop.id);
          } finally {
            this.$store.dispatch("setLoading", false);
          }
        }
        if (this.deletion_administrator) {
          try {
            await Administrator.delete(this.deletion_administrator.id);
          } finally {
            this.$store.dispatch("setLoading", false);
          }
        }

        this.deletion_shop = null;
        this.deletion_administrator = null;
        this.is_confirming = false;
        this.refresh();
      });
    },

    onCancelDeletion() {
      this.deletion_shop = null;
      this.deletion_administrator = null;
      this.is_confirming = false;
    },
  },
});
