
import { defineComponent } from "vue";
import SystemSidebar from "@/components/SystemSidebar.vue";
import SalonSidebar from "@/components/SalonSidebar.vue";
import SystemHeader from "@/components/SystemHeader.vue";
import SalonHeader from "@/components/SalonHeader.vue";

export default defineComponent({
  components: {
    SystemSidebar,
    SystemHeader,
    SalonSidebar,
    SalonHeader,
  },

  computed: {
    is_loading(): boolean {
      return this.$store.state.is_loading;
    },
    is_sidebar_open(): boolean {
      console.log(this.$store);
      return this.$store.state.is_sidebar_open;
    },

    is_system_role(): boolean {
      return this.$store.state.role == "system";
    },
  },

  methods: {
    toggleSidebarOpen() {
      this.$store.dispatch("toggleSidebarOpen");
    },

    signout() {
      this.$store.dispatch("signOut");
    },
  },
});
