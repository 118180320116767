
import { defineComponent } from "vue";
import moment from "moment";
import Customer from "@/models/Customer";

interface StaffListState {
  keyword: string;
  customers: Customer[];
  last_evaluated_key: string | null;
}

const PAGE_SIZE = 20;

export default defineComponent({
  data() {
    return {
      // is_loading: false,
      keyword: "",
      customers: [],
      last_evaluated_key: null,
    } as StaffListState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      console.log(`try to search (keyword=${this.keyword})`);

      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await Customer.search(
            this.$store.state.salon_id,
            this.last_evaluated_key,
            PAGE_SIZE
          );
          this.customers = result.customers;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },
  },
});
