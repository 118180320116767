
import { defineComponent } from "vue";
import moment from "moment";

import Trial from "@/models/Trial";
import TrialApplyment from "@/models/TrialApplyment";

interface TopicDetailState {
  // is_loading: boolean;

  trial: Trial;
  applyments: TrialApplyment[];

  selected_tab: string;
}

export default defineComponent({
  name: "TopicDetail",

  data() {
    return {
      // is_loading: false,

      trial: {} as Trial,
      applyments: [],

      selected_tab: "contents",
    } as TopicDetailState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      console.log(date);
      return moment(date).format(format);
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      const id = this.$route.params.trial_id as string;

      setTimeout(async () => {
        try {
          console.log(id);
          this.trial = await Trial.get(id);

          const results = await TrialApplyment.search(this.trial.id);
          this.applyments = results.applyments;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },
  },
});
