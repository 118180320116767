import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-2 mt-2 lg:mt-0 rounded shadow bg-white" }
const _hoisted_2 = { class: "p-6" }
const _hoisted_3 = { class: "text-xl font-bold" }
const _hoisted_4 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start"
}
const _hoisted_7 = {
  key: 1,
  class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start"
}
const _hoisted_8 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start" }
const _hoisted_9 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start" }
const _hoisted_10 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start" }
const _hoisted_11 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start" }
const _hoisted_12 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start" }
const _hoisted_13 = { class: "p-2 mt-2 rounded shadow bg-white" }
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.advertisement.title), 1),
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "link",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createElementVNode("a", {
            href: _ctx.advertisement.redirect_url,
            target: "_blank"
          }, _toDisplayString(_ctx.advertisement.redirect_url), 9, _hoisted_5)
        ]),
        (_ctx.advertisement.publish_from || _ctx.advertisement.publish_to)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
              _createElementVNode("span", null, [
                _createVNode(_component_fa, {
                  icon: "calendar",
                  class: "text-sm text-color-3 mr-2"
                })
              ]),
              _createTextVNode(" " + _toDisplayString(`${
            _ctx.advertisement.publish_from
              ? _ctx.formatDate(_ctx.advertisement.publish_from, "YYYY-MM-DD")
              : "-"
          } ~ ${
            _ctx.advertisement.publish_to
              ? _ctx.formatDate(_ctx.advertisement.publish_to, "YYYY-MM-DD")
              : "-"
          }`), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.advertisement.target_age_from || _ctx.advertisement.target_age_to)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
              _createElementVNode("span", null, [
                _createVNode(_component_fa, {
                  icon: "clock",
                  class: "text-sm text-color-3 mr-2"
                })
              ]),
              _createTextVNode(" " + _toDisplayString(`${
            _ctx.advertisement.target_age_from ? _ctx.advertisement.target_age_from : "-"
          } ~ ${
            _ctx.advertisement.target_age_to ? _ctx.advertisement.target_age_to : "-"
          }`), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_8, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "transgender",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.target_genders), 1)
        ]),
        _createElementVNode("p", _hoisted_9, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "wallet",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.target_consumptions), 1)
        ]),
        _createElementVNode("p", _hoisted_10, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "map-marked-alt",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.target_prefectures), 1)
        ]),
        _createElementVNode("p", _hoisted_11, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "eye",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createTextVNode(" " + _toDisplayString(`${_ctx.advertisement.view_count ?? ''} / ${_ctx.advertisement.click_count ?? ''}`), 1)
        ]),
        _createElementVNode("p", _hoisted_12, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "eye",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.advertisement.show_detail_page ? "詳細ページを表示する" : "詳細ページを表示しない"), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        innerHTML: _ctx.advertisement.body
      }, null, 8, _hoisted_14)
    ])
  ], 64))
}