
import { defineComponent } from "vue";
import moment from "moment";

import Advertisement from "@/models/Advertisement";

interface AdvertisementDetailState {
  // is_loading: boolean;

  advertisement: Advertisement;
}

export default defineComponent({
  name: "AdvertisementDetail",

  data() {
    return {
      // is_loading: false,

      advertisement: {} as Advertisement,
    } as AdvertisementDetailState;
  },

  computed: {
    target_genders() {
      if (!this.advertisement || !this.advertisement.target_genders) {
        return "-";
      }

      let genders = "";
      this.advertisement.target_genders.forEach((g) => {
        if (genders) {
          genders = genders + " / ";
        }
        if (g == "male") {
          genders += "男性";
        }
        if (g == "female") {
          genders += "女性";
        }
      });

      return genders;
    },

    target_consumptions() {
      if (!this.advertisement || !this.advertisement.target_consumptions) {
        return "-";
      }

      let consumptions = "";
      this.advertisement.target_consumptions.forEach((g) => {
        if (consumptions) {
          consumptions = consumptions + " / ";
        }
        if (g == "consumption_0") {
          consumptions += "0~30,000円";
        }
        if (g == "consumption_30000") {
          consumptions += "30,000~50,000円";
        }
        if (g == "consumption_50000") {
          consumptions += "50,000~100,000円";
        }
        if (g == "consumption_100000") {
          consumptions += "100,000円~";
        }
      });

      return consumptions;
    },

    target_prefectures() {
      if (!this.advertisement || !this.advertisement.target_prefectures) {
        return "-";
      }

      let prefectures = "";
      this.advertisement.target_prefectures.forEach((p) => {
        if (prefectures) {
          prefectures = prefectures + " / ";
        }
        prefectures += p;
      });

      return prefectures;
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      const id = this.$route.params.advertisement_id as string;

      setTimeout(async () => {
        try {
          console.log(id);
          this.advertisement = await Advertisement.get(id);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },
  },
});
