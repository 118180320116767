
import { defineComponent } from "vue";
import moment from "moment";
import Contest from "@/models/Contest";
import ContestEntry from "@/models/ContestEntry";

interface ContestEntryListState {
  // is_loading: boolean;
  keyword: string;
  entries: ContestEntry[];
  last_evaluated_key: string | null;
}

const PAGE_SIZE = 20;

export default defineComponent({
  data() {
    return {
      // is_loading: false,
      keyword: "",
      entries: [],
      last_evaluated_key: null,
    } as ContestEntryListState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      console.log(`try to search (keyword=${this.keyword})`);

      if (this.$store.state.is_loading) {
        return;
      }

      const id = this.$route.params.contest_id as string;

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await ContestEntry.search(
            id,
            this.last_evaluated_key,
            PAGE_SIZE
          );
          this.entries = result.entries;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },
  },
});
