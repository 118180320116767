import { Auth } from "aws-amplify";
import axios from "axios";

interface CustomerJson {
  id: string;
  family_name: string;
  given_name: string;
  email: string;
  selfy_image_url: string | null;
  gender: string | null;
  date_of_birth: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface CustomerSearchResult {
  customers: Customer[];
  last_evaluated_key: string | null;
}

export default class Customer {
  id: string;
  family_name: string;
  given_name: string;
  email: string;
  selfy_image_url: string | null;
  gender: string | null;
  date_of_birth: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    family_name: string,
    given_name: string,
    email: string,
    selfy_image_url: string | null,
    gender: string | null,
    date_of_birth: Date | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.family_name = family_name;
    this.given_name = given_name;
    this.email = email;
    this.selfy_image_url = selfy_image_url;
    this.gender = gender;
    this.date_of_birth = date_of_birth;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async search(
    salon_id: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<CustomerSearchResult> {
    console.log(
      `Customer.search(${salon_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        salon_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/customers`,
      config
    );

    const list: Customer[] = [];
    response.data.data.forEach((json: CustomerJson) => {
      list.push(
        new Customer(
          json.id,
          json.family_name,
          json.given_name,
          json.email,
          json.selfy_image_url,
          json.gender,
          json.date_of_birth ? new Date(json.date_of_birth) : null,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      customers: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }
}
