import { Auth } from "aws-amplify";
import axios from "axios";

import Bank from "@/models/Bank";
import Branch from "@/models/Branch";

interface AffeliateMonthlyJson {
  id: string;
  ym: number;
  type: string;
  target_id: string;
  target_name: string;
  salon_id: string;
  salon_name: string;
  count: number;
  quantity: number;
  price: number;
  tax: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface AffeliateMonthlySearchResult {
  affeliates: AffeliateMonthly[];
  last_evaluated_key: string | null;
}

export default class AffeliateMonthly {
  id: string;
  ym: number;
  type: string;
  target_id: string;
  target_name: string;
  salon_id: string;
  salon_name: string;
  count: number;
  quantity: number;
  price: number;
  tax: number;
  created_at: Date;
  updated_at: Date;

  constructor(
    id: string,
    ym: number,
    type: string,
    target_id: string,
    target_name: string,
    salon_id: string,
    salon_name: string,
    count: number,
    quantity: number,
    price: number,
    tax: number,
    created_at: Date,
    updated_at: Date
  ) {
    this.id = id;
    this.ym = ym;
    this.type = type;
    this.target_id = target_id;
    this.target_name = target_name;
    this.salon_id = salon_id;
    this.salon_name = salon_name;
    this.count = count;
    this.quantity = quantity;
    this.price = price;
    this.tax = tax;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

  static async search(
    type: string,
    salonId: string | null,
    ym: number | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<AffeliateMonthlySearchResult> {
    console.log(
      `AffeliateMonthly.search(${type}, ${salonId}, ${ym}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        type,
        salon_id: salonId,
        ym,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/affeliates/monthly`,
      config
    );

    const list: AffeliateMonthly[] = [];
    response.data.data.forEach((json: AffeliateMonthlyJson) => {
      list.push(
        new AffeliateMonthly(
          json.id,
          json.ym,
          json.type,
          json.target_id,
          json.target_name,
          json.salon_id,
          json.salon_name,
          json.count,
          json.quantity,
          json.price,
          json.tax,
          new Date(json.created_at),
          new Date(json.updated_at)
        )
      );
    });

    return {
      affeliates: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }
}
