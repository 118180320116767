
import { defineComponent, Ref } from "vue";
import { FieldContext, useField, useForm } from "vee-validate";
import * as yup from "yup";

import { PREFECTURES } from "@/utils/prefectures";
import Dealer from "@/models/Dealer";
import Bank from "@/models/Bank";
import Branch from "@/models/Branch";
import Prefecture from "@/models/Prefecture";
import AddressUtil from "@/utils/AddressUtil";

interface DealerEditState {
  // is_loading: boolean;

  dealer: Dealer | null;

  dealer_name_field: FieldContext<string>;
  dealer_name: Ref<string>;
  dealer_name_error: Ref<string>;

  dealer_kana_field: FieldContext<string>;
  dealer_kana: Ref<string>;
  dealer_kana_error: Ref<string>;

  postcode_field: FieldContext<string>;
  postcode: Ref<string>;
  postcode_error: Ref<string>;

  prefectures: string[];
  prefecture_field: FieldContext<string>;
  prefecture: Ref<string>;
  prefecture_error: Ref<string>;

  city_field: FieldContext<string>;
  city: Ref<string>;
  city_error: Ref<string>;

  address_field: FieldContext<string>;
  address: Ref<string>;
  address_error: Ref<string>;

  building_field: FieldContext<string>;
  building: Ref<string>;
  building_error: Ref<string>;

  telephone_field: FieldContext<string>;
  telephone: Ref<string>;
  telephone_error: Ref<string>;

  email_field: FieldContext<string>;
  email: Ref<string>;
  email_error: Ref<string>;

  selected_bank: Bank | null;
  bank_suggests: Bank[];
  bank_keyword: string;

  selected_branch: Branch | null;
  branch_suggests: Branch[];
  branch_keyword: string;

  account_type_field: FieldContext<string>;
  account_type: Ref<string>;
  account_type_error: Ref<string>;

  account_no_field: FieldContext<string>;
  account_no: Ref<string>;
  account_no_error: Ref<string>;

  account_name_field: FieldContext<string>;
  account_name: Ref<string>;
  account_name_error: Ref<string>;

  administrator_name_field: FieldContext<string>;
  administrator_name: Ref<string>;
  administrator_name_error: Ref<string>;

  administrator_kana_field: FieldContext<string>;
  administrator_kana: Ref<string>;
  administrator_kana_error: Ref<string>;

  administrator_email_field: FieldContext<string>;
  administrator_email: Ref<string>;
  administrator_email_error: Ref<string>;

  message: string | null;
}

export default defineComponent({
  components: {
    // Autocomplete,
  },

  data() {
    const formSchema = yup.object({
      dealer_name: yup.string().required("必須項目です"),
      dealer_kana: yup.string().kana().required("必須項目です"),
      postcode: yup.string().postcode().required("必須項目です"),
      prefecture: yup.string().required("必須項目です"),
      city: yup.string().required("必須項目です"),
      address: yup.string().required("必須項目です"),
      building: yup.string(),
      telephone: yup.string().phonenumber().required("必須項目です"),
      email: yup.string().email().required("必須項目です"),
      account_type: yup.string().required("必須項目です"),
      account_no: yup.string().accountno().required("必須項目です"),
      account_name: yup.string().kana().required("必須項目です"),
      administrator_name: yup.string().required("必須項目です"),
      administrator_kana: yup.string().kana().required("必須項目です"),
      administrator_email: yup.string().email().required("必須項目です"),
    });

    useForm({ validationSchema: formSchema });

    const dealer_name = useField<string>("dealer_name");
    const dealer_kana = useField<string>("dealer_kana");
    const postcode = useField<string>("postcode");
    const prefecture = useField<string>("prefecture");
    const city = useField<string>("city");
    const address = useField<string>("address");
    const building = useField<string>("building");
    const telephone = useField<string>("telephone");
    const email = useField<string>("email");
    const account_type = useField<string>("account_type");
    const account_no = useField<string>("account_no");
    const account_name = useField<string>("account_name");
    const administrator_name = useField<string>("administrator_name");
    const administrator_kana = useField<string>("administrator_kana");
    const administrator_email = useField<string>("administrator_email");

    return {
      // is_loading: false,

      dealer: null,

      prefectures: PREFECTURES,

      dealer_name_field: dealer_name,
      dealer_name: dealer_name.value,
      dealer_name_error: dealer_name.errorMessage,

      dealer_kana_field: dealer_kana,
      dealer_kana: dealer_kana.value,
      dealer_kana_error: dealer_kana.errorMessage,

      postcode_field: postcode,
      postcode: postcode.value,
      postcode_error: postcode.errorMessage,

      prefecture_field: prefecture,
      prefecture: prefecture.value,
      prefecture_error: prefecture.errorMessage,

      city_field: city,
      city: city.value,
      city_error: city.errorMessage,

      address_field: address,
      address: address.value,
      address_error: address.errorMessage,

      building_field: building,
      building: building.value,
      building_error: building.errorMessage,

      telephone_field: telephone,
      telephone: telephone.value,
      telephone_error: telephone.errorMessage,

      email_field: email,
      email: email.value,
      email_error: email.errorMessage,

      selected_bank: null,
      bank_suggests: [],
      bank_keyword: "",

      selected_branch: null,
      branch_suggests: [],
      branch_keyword: "",

      account_type_field: account_type,
      account_type: account_type.value,
      account_type_error: account_type.errorMessage,

      account_no_field: account_no,
      account_no: account_no.value,
      account_no_error: account_no.errorMessage,

      account_name_field: account_name,
      account_name: account_name.value,
      account_name_error: account_name.errorMessage,

      administrator_name_field: administrator_name,
      administrator_name: administrator_name.value,
      administrator_name_error: administrator_name.errorMessage,

      administrator_kana_field: administrator_kana,
      administrator_kana: administrator_kana.value,
      administrator_kana_error: administrator_kana.errorMessage,

      administrator_email_field: administrator_email,
      administrator_email: administrator_email.value,
      administrator_email_error: administrator_email.errorMessage,

      message: null,
    } as DealerEditState;
  },

  computed: {
    selected_bank_name(): string {
      return this.selected_bank ? this.selected_bank.name : "";
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const id = this.$route.params.dealer_id as string | null;

          console.log(id);

          if (id) {
            this.dealer = await Dealer.get(id);

            if (this.dealer) {
              this.dealer_name = this.dealer.name;
              this.dealer_kana = this.dealer.kana;
              this.postcode = this.dealer.postcode;
              this.prefecture = this.dealer.prefecture;
              this.city = this.dealer.city;
              this.address = this.dealer.address;
              this.building = this.dealer.building;
              this.telephone = this.dealer.telephone;
              this.email = this.dealer.email;

              this.selected_bank = new Bank(
                this.dealer.bank_code,
                this.dealer.bank_name
              );
              this.bank_keyword = this.dealer.bank_name;

              this.selected_branch = new Branch(
                this.dealer.branch_code,
                this.dealer.branch_name
              );
              this.branch_keyword = this.dealer.branch_name;

              this.account_type = this.dealer.account_type;
              this.account_no = this.dealer.account_no;
              this.account_name = this.dealer.account_name;
            }
          }
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onPostcodeChanged() {
      console.log(`${this.postcode} ${this.postcode_error}`);
      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await AddressUtil.search(this.postcode);
          if (!result || result.length == 0) {
            return;
          }
          const data = result[0] as any;
          this.prefecture = Prefecture.all().find((p) => p == data.pref) || "";
          // if (this.prefecture) {
          //   this.prefecture_keyword = this.prefecture;
          // }
          this.city = data.city as string;
          this.address = data.town as string;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onBanknameChanged(e: Event) {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }
      this.bank_keyword = e.target.value;
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      setTimeout(async () => {
        try {
          if (!this.bank_keyword) {
            return;
          }

          const suggests = await Bank.search(this.bank_keyword);

          this.bank_suggests = suggests;
          console.log(suggests);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      }, 1000);
    },

    onBankSelected(bank: Bank) {
      this.selected_bank = bank;
      this.selected_branch = null;
      this.bank_keyword = bank.name;
      this.bank_suggests.length = 0;
    },

    onBranchnameChanged(e: Event) {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }
      this.branch_keyword = e.target.value;
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      setTimeout(async () => {
        try {
          if (!this.branch_keyword) {
            return;
          }
          if (!this.selected_bank) {
            return;
          }

          const suggests = await Branch.search(
            this.selected_bank.code,
            this.branch_keyword
          );

          this.branch_suggests = suggests;
          console.log(suggests);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      }, 1000);
    },

    onBranchSelected(branch: Branch) {
      this.selected_branch = branch;
      this.branch_keyword = branch.name;
      this.branch_suggests.length = 0;
    },

    async validate(): Promise<boolean> {
      if (this.dealer) {
        await this.dealer_name_field.validate();
        await this.dealer_kana_field.validate();
        await this.postcode_field.validate();
        await this.prefecture_field.validate();
        await this.city_field.validate();
        await this.address_field.validate();
        await this.building_field.validate();
        await this.telephone_field.validate();
        await this.email_field.validate();
        await this.account_type_field.validate();
        await this.account_no_field.validate();
        await this.account_name_field.validate();

        return (
          !this.dealer_name_error &&
          !this.dealer_kana_error &&
          !this.postcode_error &&
          !this.prefecture_error &&
          !this.city_error &&
          !this.address_error &&
          !this.building_error &&
          !this.telephone_error &&
          !this.email_error &&
          !this.account_type_error &&
          !this.account_no_error &&
          !this.account_name_error
        );
      } else {
        await this.dealer_name_field.validate();
        await this.dealer_kana_field.validate();
        await this.postcode_field.validate();
        await this.prefecture_field.validate();
        await this.city_field.validate();
        await this.address_field.validate();
        await this.building_field.validate();
        await this.telephone_field.validate();
        await this.email_field.validate();
        await this.account_type_field.validate();
        await this.account_no_field.validate();
        await this.account_name_field.validate();
        await this.administrator_name_field.validate();
        await this.administrator_kana_field.validate();
        await this.administrator_email_field.validate();

        return (
          !this.dealer_name_error &&
          !this.dealer_kana_error &&
          !this.postcode_error &&
          !this.prefecture_error &&
          !this.city_error &&
          !this.address_error &&
          !this.building_error &&
          !this.telephone_error &&
          !this.email_error &&
          !this.account_type_error &&
          !this.account_no_error &&
          !this.account_name_error &&
          !this.administrator_name_error &&
          !this.administrator_kana_error &&
          !this.administrator_email_error
        );
      }
    },

    onSave() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.message = null;

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const valid = await this.validate();
          if (!valid) {
            return;
          }

          if (!this.selected_bank) {
            return;
          }
          if (!this.selected_branch) {
            return;
          }
          if (this.dealer) {
            await Dealer.update(
              this.dealer.id,
              this.dealer_name,
              this.dealer_kana,
              this.postcode,
              this.prefecture,
              this.city,
              this.address,
              this.building,
              this.telephone,
              this.email,
              this.selected_bank.code,
              this.selected_bank.name,
              this.selected_branch.code,
              this.selected_branch.name,
              this.account_type,
              this.account_no,
              this.account_name
            );
          } else {
            await Dealer.register(
              this.dealer_name,
              this.dealer_kana,
              this.postcode,
              this.prefecture,
              this.city,
              this.address,
              this.building,
              this.telephone,
              this.email,
              this.selected_bank.code,
              this.selected_bank.name,
              this.selected_branch.code,
              this.selected_branch.name,
              this.account_type,
              this.account_no,
              this.account_name,
              this.administrator_name,
              this.administrator_kana,
              this.administrator_email
            );
          }

          this.$router.push(`/dealers`);
        } catch (ex) {
          this.message = `${ex}`;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
      console.log(this);
    },
  },
});
