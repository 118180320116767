<template>
  <div>
    <pre>
特定商取引法に基づく表記
販売業者名
株式会社 SALON MEDIA

運営責任者名
福島和人

所在地
〒812-0026 福岡市博多区上川端町12-28安田第１ビル3階

電話番号
050-5327-7929

電話受付時間
10:00〜18:00

販売価格
各商品ページをご参照ください

商品代金以外の必要料金
消費税 送料(各商品ページをご参照ください)

お届け時期
ご注文後、直ちに商品を発送いたします

お支払方法
代金引換

返品・交換・キャンセル等
ご注文後の返品・返却等はお受けいたしかねます。商品が不良の場合のみ交換いたします。

返品期限
商品到着より７日以内にご連絡下さい。

返品送料
不良品の場合は弊社が負担いたします。それ以外はお客様のご負担となります。
    </pre>
  </div>
</template>
