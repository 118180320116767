import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface TrialJson {
  id: string;
  type: string;
  salon_ids: string[];
  title: string;
  main_image_url: string;
  body: string;
  publish_from: Date | null;
  publish_to: Date | null;
  receivable: boolean;
  view_count: number;
  click_count: number;
  applied_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface TrialSearchResult {
  trials: Trial[];
  last_evaluated_key: string | null;
}

export default class Trial {
  id: string;
  salon_ids: string[];
  title: string;
  main_image_url: string;
  body: string;
  publish_from: Date | null;
  publish_to: Date | null;
  receivable: boolean;
  view_count: number;
  click_count: number;
  applied_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    salon_ids: string[],
    title: string,
    main_image_url: string,
    body: string,
    publish_from: Date | null,
    publish_to: Date | null,
    receivable: boolean,
    view_count: number,
    click_count: number,
    applied_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.salon_ids = salon_ids;
    this.title = title;
    this.main_image_url = main_image_url;
    this.body = body;
    this.publish_from = publish_from;
    this.publish_to = publish_to;
    this.receivable = receivable;
    this.view_count = view_count;
    this.click_count = click_count;
    this.applied_count = applied_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Trial> {
    console.log(`Trial.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/trials/${id}`,
      config
    );

    const json = response.data.data as TrialJson;

    return new Trial(
      json.id,
      json.salon_ids,
      json.title,
      json.main_image_url,
      json.body,
      json.publish_from ? new Date(json.publish_from) : null,
      json.publish_to ? new Date(json.publish_to) : null,
      json.receivable,
      json.view_count,
      json.click_count,
      json.applied_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<TrialSearchResult> {
    console.log(`Trial.searchTrend(${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/trials`,
      config
    );

    const list: Trial[] = [];
    response.data.data.forEach((json: TrialJson) => {
      list.push(
        new Trial(
          json.id,
          json.salon_ids,
          json.title,
          json.main_image_url,
          json.body,
          json.publish_from ? new Date(json.publish_from) : null,
          json.publish_to ? new Date(json.publish_to) : null,
          json.receivable,
          json.view_count,
          json.click_count,
          json.applied_count,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      trials: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    salon_ids: string[],
    title: string,
    main_image_url: string,
    body: string,
    publish_from: Date | null,
    publish_to: Date | null,
    receivable: boolean
  ): Promise<Trial> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/trials`,
      {
        salon_ids,
        title,
        main_image_url,
        body,
        publish_from: publish_from
          ? moment(publish_from).format("YYYY-MM-DD")
          : null,
        publish_to: publish_to ? moment(publish_to).format("YYYY-MM-DD") : null,
        receivable,
      },
      config
    );

    console.log(response);

    const json = response.data as TrialJson;

    return new Trial(
      json.id,
      json.salon_ids,
      json.title,
      json.main_image_url,
      json.body,
      json.publish_from ? new Date(json.publish_from) : null,
      json.publish_to ? new Date(json.publish_to) : null,
      json.receivable,
      json.view_count,
      json.click_count,
      json.applied_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    salon_ids: string[],
    title: string,
    main_image_url: string,
    body: string,
    publish_from: Date | null,
    publish_to: Date | null,
    receivable: boolean
  ): Promise<Trial> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/trials/${id}`,
      {
        id,
        salon_ids,
        title,
        main_image_url,
        body,
        publish_from: publish_from
          ? moment(publish_from).format("YYYY-MM-DD")
          : null,
        publish_to: publish_to ? moment(publish_to).format("YYYY-MM-DD") : null,
        receivable,
      },
      config
    );

    console.log(response);

    const json = response.data as TrialJson;

    return new Trial(
      json.id,
      json.salon_ids,
      json.title,
      json.main_image_url,
      json.body,
      json.publish_from ? new Date(json.publish_from) : null,
      json.publish_to ? new Date(json.publish_to) : null,
      json.receivable,
      json.view_count,
      json.click_count,
      json.applied_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Trial> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/trials/${id}`,
      config
    );

    console.log(response);

    const json = response.data as TrialJson;

    return new Trial(
      json.id,
      json.salon_ids,
      json.title,
      json.main_image_url,
      json.body,
      json.publish_from ? new Date(json.publish_from) : null,
      json.publish_to ? new Date(json.publish_to) : null,
      json.receivable,
      json.view_count,
      json.click_count,
      json.applied_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
