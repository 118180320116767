import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container px-5 py-2 mx-auto" }
const _hoisted_2 = { class: "flex flex-wrap -m-1 md:-m-2" }
const _hoisted_3 = { class: "w-full p-1 md:p-2" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-gray-400" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.designs, (design) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex flex-wrap w-1/3",
          key: design.id
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", {
              href: design.permalink
            }, [
              _createElementVNode("img", {
                src: design.medias[0].thumbnail_url
              }, null, 8, _hoisted_5)
            ], 8, _hoisted_4),
            _createElementVNode("p", _hoisted_6, _toDisplayString(design.salon_name) + " | " + _toDisplayString(design.staff_name), 1),
            _createElementVNode("button", {
              class: _normalizeClass(["mt-2 bg-red-700 text-xs text-white p-1 rounded", { 'bg-red-700' : !design.blocking, 'bg-green-600': design.blocking }]),
              onClick: ($event: any) => (_ctx.toggleBlocking(design))
            }, _toDisplayString(design.blocking ? "ブロックを外す" : "ブロックする"), 11, _hoisted_7)
          ])
        ]))
      }), 128))
    ])
  ]))
}