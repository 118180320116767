import { Auth } from "aws-amplify";
import axios from "axios";

import ApiResponse from "@/models/ApiResponse";

interface SalonJson {
  id: string;
  dealer_id: string;
  dealer_name: string;
  name: string;
  kana: string;
  display_name: string;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
  telephone: string;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: string;
  account_no: string;
  account_name: string;
  qr_codes_zip_name: string | null;
  shop_count: number;
  staff_count: number;
  user_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface SalonSearchResult {
  salons: Salon[];
  last_evaluated_key: string | null;
}

export default class Salon {
  id: string;
  dealer_id: string;
  dealer_name: string;
  name: string;
  kana: string;
  display_name: string;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
  telephone: string;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: string;
  account_no: string;
  account_name: string;
  qr_codes_zip_name: string | null;
  shop_count: number;
  staff_count: number;
  user_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  is_selected = false;

  constructor(
    id: string,
    dealer_id: string,
    dealer_name: string,
    name: string,
    kana: string,
    display_name: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string,
    qr_codes_zip_name: string | null,
    shop_count: number,
    staff_count: number,
    user_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.dealer_id = dealer_id;
    this.dealer_name = dealer_name;
    this.name = name;
    this.kana = kana;
    this.display_name = display_name;
    this.postcode = postcode;
    this.prefecture = prefecture;
    this.city = city;
    this.address = address;
    this.building = building;
    this.telephone = telephone;
    this.bank_code = bank_code;
    this.bank_name = bank_name;
    this.branch_code = branch_code;
    this.branch_name = branch_name;
    this.account_type = account_type;
    this.account_no = account_no;
    this.account_name = account_name;
    this.qr_codes_zip_name = qr_codes_zip_name;
    this.shop_count = shop_count;
    this.staff_count = staff_count;
    this.user_count = user_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Salon> {
    console.log(`Salon.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/salons/${id}`,
      config
    );

    const json = response.data.data as SalonJson;
    console.log(json);

    return new Salon(
      json.id,
      json.dealer_id,
      json.dealer_name,
      json.name,
      json.kana,
      json.display_name,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.qr_codes_zip_name,
      json.shop_count,
      json.staff_count,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<SalonSearchResult> {
    console.log(`Salon.search(${keyword}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/salons`,
      config
    );

    const list: Salon[] = [];
    response.data.data.forEach((json: SalonJson) => {
      list.push(
        new Salon(
          json.id,
          json.dealer_id,
          json.dealer_name,
          json.name,
          json.kana,
          json.display_name,
          json.postcode,
          json.prefecture,
          json.city,
          json.address,
          json.building,
          json.telephone,
          json.bank_code,
          json.bank_name,
          json.branch_code,
          json.branch_name,
          json.account_type,
          json.account_no,
          json.account_name,
          json.qr_codes_zip_name,
          json.shop_count,
          json.staff_count,
          json.user_count,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      salons: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    dealer_id: string,
    salon_name: string,
    salon_kana: string,
    salon_display_name: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    // email: string,
    shop_name: string,
    shop_kana: string,
    shop_postcode: string,
    shop_prefecture: string,
    shop_city: string,
    shop_address: string,
    shop_building: string,
    shop_telephone: string,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string,
    administrator_name: string,
    administrator_kana: string,
    administrator_email: string
  ): Promise<Salon> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/salons`,
      {
        dealer_id: dealer_id,
        name: salon_name,
        kana: salon_kana,
        display_name: salon_display_name,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
        // email: email,
        shop_name: shop_name,
        shop_kana: shop_kana,
        shop_postcode: shop_postcode,
        shop_prefecture: shop_prefecture,
        shop_city: shop_city,
        shop_address: shop_address,
        shop_building: shop_building,
        shop_telephone: shop_telephone,
        bank_code: bank_code,
        bank_name: bank_name,
        branch_code: branch_code,
        branch_name: branch_name,
        account_type: account_type,
        account_no: account_no,
        account_name: account_name,
        administrator_name: administrator_name,
        administrator_kana: administrator_kana,
        administrator_email: administrator_email,
      },
      config
    );

    console.log(response);

    if (response.data.code !== "0") {
      const message = response.data.data as string;

      if (message == "the administrator email already exist") {
        throw "すでに登録されているメールアドレスです";
      }

      throw "サロンの登録に失敗しました";
    }

    const json = response.data.data as SalonJson;

    return new Salon(
      json.id,
      json.dealer_id,
      json.dealer_name,
      json.name,
      json.kana,
      json.display_name,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.qr_codes_zip_name,
      json.shop_count,
      json.staff_count,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    dealer_id: string,
    name: string,
    kana: string,
    display_name: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    // email: string,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string
  ): Promise<Salon> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/salons/${id}`,
      {
        id,
        dealer_id,
        name,
        kana,
        display_name,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
        // email: email,
        bank_code: bank_code,
        bank_name: bank_name,
        branch_code: branch_code,
        branch_name: branch_name,
        account_type: account_type,
        account_no: account_no,
        account_name: account_name,
      },
      config
    );

    console.log(response);

    const json = response.data.data as SalonJson;

    return new Salon(
      json.id,
      json.dealer_id,
      json.dealer_name,
      json.name,
      json.kana,
      json.display_name,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.qr_codes_zip_name,
      json.shop_count,
      json.staff_count,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<ApiResponse<Salon>> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/salons/${id}`,
      config
    );

    console.log(response);

    if (response.data.code !== "0") {
      return new ApiResponse<Salon>(false, response.data.message, null);
    }

    const json = response.data.data as SalonJson;

    return new ApiResponse<Salon>(
      true,
      null,
      new Salon(
        json.id,
        json.dealer_id,
        json.dealer_name,
        json.name,
        json.kana,
        json.display_name,
        json.postcode,
        json.prefecture,
        json.city,
        json.address,
        json.building,
        json.telephone,
        json.bank_code,
        json.bank_name,
        json.branch_code,
        json.branch_name,
        json.account_type,
        json.account_no,
        json.account_name,
        json.qr_codes_zip_name,
        json.shop_count,
        json.staff_count,
        json.user_count,
        new Date(json.created_at),
        new Date(json.updated_at),
        json.deleted_at ? new Date(json.deleted_at) : null
    ));
  }
}
