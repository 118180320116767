
import { defineComponent, Ref } from "vue";
import { FieldContext, useField, useForm } from "vee-validate";
import * as yup from "yup";
import { Storage } from "aws-amplify";
import { uuid } from "vue-uuid";
import moment from "moment";
import Editor from "@tinymce/tinymce-vue";

import Contest from "@/models/Contest";

interface ContestEditState {
  // is_loading: boolean;

  contest: Contest | null;

  title_field: FieldContext<string>;
  title: Ref<string>;
  title_error: Ref<string>;

  main_image_url_field: FieldContext<string>;
  main_image_url: Ref<string>;
  main_image_url_error: Ref<string>;

  publish_date_field: FieldContext<string>;
  publish_date: Ref<string>;
  publish_date_error: Ref<string>;

  vote_start_field: FieldContext<string>;
  vote_start: Ref<string>;
  vote_start_error: Ref<string>;

  vote_end_field: FieldContext<string>;
  vote_end: Ref<string>;
  vote_end_error: Ref<string>;

  result_announcement_date_field: FieldContext<string>;
  result_announcement_date: Ref<string>;
  result_announcement_date_error: Ref<string>;

  theme_field: FieldContext<string>;
  theme: Ref<string>;
  theme_error: Ref<string>;

  description_field: FieldContext<string>;
  description: Ref<string>;
  description_error: Ref<string>;

  requirement_field: FieldContext<string>;
  requirement: Ref<string>;
  requirement_error: Ref<string>;

  reward_field: FieldContext<string>;
  reward: Ref<string>;
  reward_error: Ref<string>;

  sponser_images: string[];

  mceCallback: ((uri: string, option: any) => void) | null;
}

export default defineComponent({
  components: {
    Editor,
  },

  data() {
    const formSchema = yup.object({
      title: yup.string().required("必須項目です"),
      main_image_url: yup.string().required("必須項目です"),
      publish_date: yup.string().required("必須項目です"),
      vote_start: yup.string().required("必須項目です"),
      vote_end: yup
        .string()
        .required("必須項目です")
        .when("vote_start", {
          is: false,
          then: yup.string().postcode().required("必須項目です"),
        }),
      result_announcement_date: yup.string().required("必須項目です"),
      theme: yup.string().required("必須項目です"),
      description: yup.string().required("必須項目です"),
      requirement: yup.string().required("必須項目です"),
      reward: yup.string().required("必須項目です"),
    });

    useForm({ validationSchema: formSchema });

    const title = useField<string>("title");
    const main_image_url = useField<string>("main_image_url");
    const publish_date = useField<string>("publish_date");
    const vote_start = useField<string>("vote_start");
    const vote_end = useField<string>("vote_end");
    const result_announcement_date = useField<string>(
      "result_announcement_date"
    );
    const theme = useField<string>("theme");
    const description = useField<string>("description");
    const requirement = useField<string>("requirement");
    const reward = useField<string>("reward");

    return {
      // is_loading: false,

      contest: null,

      title_field: title,
      title: title.value,
      title_error: title.errorMessage,

      main_image_url_field: main_image_url,
      main_image_url: main_image_url.value,
      main_image_url_error: main_image_url.errorMessage,

      publish_date_field: publish_date,
      publish_date: publish_date.value,
      publish_date_error: publish_date.errorMessage,

      vote_start_field: vote_start,
      vote_start: vote_start.value,
      vote_start_error: vote_start.errorMessage,

      vote_end_field: vote_end,
      vote_end: vote_end.value,
      vote_end_error: vote_end.errorMessage,

      result_announcement_date_field: result_announcement_date,
      result_announcement_date: result_announcement_date.value,
      result_announcement_date_error: result_announcement_date.errorMessage,

      theme_field: theme,
      theme: theme.value,
      theme_error: theme.errorMessage,

      description_field: description,
      description: description.value,
      description_error: description.errorMessage,

      requirement_field: requirement,
      requirement: requirement.value,
      requirement_error: requirement.errorMessage,

      reward_field: reward,
      reward: reward.value,
      reward_error: reward.errorMessage,

      sponser_images: [] as string[],
    } as ContestEditState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      const id = this.$route.params.contest_id as string | null;

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          if (id) {
            this.contest = await Contest.get(id);

            console.log(this.contest);

            this.title = this.contest.title;
            this.main_image_url = this.contest.main_image_url;

            if (this.contest.publish_date) {
              const date = this.contest.publish_date as Date;
              this.publish_date = moment(date).format("YYYY-MM-DD");
            }

            if (this.contest.vote_start) {
              const date = this.contest.vote_start as Date;
              this.vote_start = moment(date).format("YYYY-MM-DD");
            }

            if (this.contest.vote_end) {
              const date = this.contest.vote_end as Date;
              this.vote_end = moment(date).format("YYYY-MM-DD");
            }

            if (this.contest.result_announcement_date) {
              const date = this.contest.result_announcement_date as Date;
              this.result_announcement_date = moment(date).format("YYYY-MM-DD");
            }

            this.theme = this.contest.theme;
            this.description = this.contest.description;
            this.requirement = this.contest.requirement;
            this.reward = this.contest.reward;
            this.sponser_images = this.contest.sponser_images;
          }
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onInputFileChanged(e: any) {
      console.log(e);

      const files = e.target.files as File[];
      if (files.length == 0) {
        return;
      }

      const file = files[0];

      this.upload(file);
    },

    onFilePicked(callback: (uri: string, option: any) => void) {
      this.mceCallback = callback;
      const uploadFile = this.$refs.uploadFile as HTMLInputElement;
      uploadFile.click();
    },

    onMainImageDropped(e: DragEvent) {
      console.log("onMainImageDropped");
      if (!e.dataTransfer) {
        return;
      }

      const file = e.dataTransfer.files[0];
      this.upload(file);
    },

    removeMainImage() {
      this.main_image_url = "";
    },

    upload(file: File) {
      const split = file.name.split(".");
      const filename = uuid.v4();
      const extension = split[split.length - 1];
      const contentType = extension == "png" ? "image/png" : "image/jpg";

      setTimeout(async () => {
        Storage.configure({ customPrefix: { public: "contests" } });
        const s3response = await Storage.put(
          `/${filename}.${extension}`,
          file,
          {
            contentType: contentType,
          }
        );

        console.log(s3response);

        this.main_image_url = `${process.env.VUE_APP_HOST}/contests/${filename}.${extension}`;
      });
    },

    onSponserImageDropped(e: DragEvent) {
      console.log("onMainImageDropped");
      if (!e.dataTransfer) {
        return;
      }

      const file = e.dataTransfer.files[0];
      const split = e.dataTransfer.files[0].name.split(".");
      const filename = uuid.v4();
      const extension = split[split.length - 1];
      const contentType = extension == "png" ? "image/png" : "image/jpg";

      setTimeout(async () => {
        Storage.configure({ customPrefix: { public: "contests" } });
        const s3response = await Storage.put(
          `/${filename}.${extension}`,
          file,
          {
            contentType: contentType,
          }
        );

        console.log(s3response);

        this.sponser_images.push(
          `${process.env.VUE_APP_HOST}/contests/${filename}.${extension}`
        );
      });
    },

    removeSponserImage(image: string) {
      this.sponser_images.splice(this.sponser_images.indexOf(image), 1);
    },

    async onUploadFileChanged() {
      const uploadFile = this.$refs.uploadFile as HTMLInputElement;

      if (!uploadFile.files) {
        return;
      }
      try {
        const split = uploadFile.files[0].name.split(".");
        const extension = split[split.length - 1];
        const filename = `${uuid.v4()}.${extension}`;
        Storage.configure({ customPrefix: { public: "contests" } });
        await Storage.put(`/${filename}`, uploadFile.files[0], {
          contentType: extension == "png" ? "image/png" : "image/jpeg",
        });

        uploadFile.value = "";

        if (this.mceCallback) {
          this.mceCallback(
            `${process.env.VUE_APP_HOST}/contests/${filename}`,
            {}
          );
        }
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    },

    async validate(): Promise<boolean> {
      await this.title_field.validate();
      await this.main_image_url_field.validate();
      await this.publish_date_field.validate();
      await this.vote_start_field.validate();
      await this.vote_end_field.validate();
      await this.result_announcement_date_field.validate();
      await this.theme_field.validate();
      await this.description_field.validate();
      await this.requirement_field.validate();
      await this.reward_field.validate();

      return (
        !this.title_error &&
        !this.main_image_url_error &&
        !this.publish_date_error &&
        !this.vote_start_error &&
        !this.vote_end_error &&
        !this.result_announcement_date_error &&
        !this.theme_error &&
        !this.description_error &&
        !this.requirement_error &&
        !this.reward_error
      );
    },

    onSave() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const valid = await this.validate();
          if (!valid) {
            return;
          }

          await Contest.register(
            this.title,
            this.main_image_url,
            this.theme,
            this.description,
            this.requirement,
            this.reward,
            moment(this.publish_date).toDate(),
            moment(this.vote_start).toDate(),
            moment(this.vote_end).toDate(),
            moment(this.result_announcement_date).toDate(),
            this.sponser_images
          );

          this.$router.push(`/contests`);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
      console.log(this);
    },
  },
});
