
import { defineComponent } from "vue";
import { Authenticator } from "@aws-amplify/ui-vue";
import { Auth } from 'aws-amplify';
import "@aws-amplify/ui-vue/styles.css";

export default defineComponent({
  name: "Proposal",
  components: {
    Authenticator,
  },

  data() {
    return {
      services: {
        handleSignIn: async ({ username, password }: { username: string, password: string }) => {
          return Auth.signIn(username.toString().trim(), password.toString().trim());
        }
      },
    };
  },
});
