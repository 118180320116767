import { Auth } from "aws-amplify";
import axios from "axios";

import Bank from "@/models/Bank";
import Branch from "@/models/Branch";

interface ShopJson {
  id: string;
  salon_id: string;
  salon_name: string;
  name: string;
  kana: string;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
  telephone: string;
  staff_count: number;
  user_count: number;
  is_main: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface ShopSearchResult {
  shops: Shop[];
  last_evaluated_key: string | null;
}

export default class Shop {
  id: string;
  salon_id: string;
  salon_name: string;
  name: string;
  kana: string;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
  telephone: string;
  staff_count: number;
  user_count: number;
  is_main: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  selected: boolean;

  constructor(
    id: string,
    salon_id: string,
    salon_name: string,
    name: string,
    kana: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    staff_count: number,
    user_count: number,
    is_main: boolean,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.salon_id = salon_id;
    this.salon_name = salon_name;
    this.name = name;
    this.kana = kana;
    this.postcode = postcode;
    this.prefecture = prefecture;
    this.city = city;
    this.address = address;
    this.building = building;
    this.telephone = telephone;
    this.staff_count = staff_count;
    this.user_count = user_count;
    this.is_main = is_main;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.selected = false;
  }

  static async get(id: string): Promise<Shop> {
    console.log(`Shop.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/shops/${id}`,
      config
    );

    const json = response.data.data as ShopJson;

    return new Shop(
      json.id,
      json.salon_id,
      json.salon_name,
      json.name,
      json.kana,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.staff_count,
      json.user_count,
      json.is_main,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    salon_id: string,
    keyword: string | null = null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<ShopSearchResult> {
    console.log(`Shop.search(${salon_id}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        salon_id,
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/shops`,
      config
    );

    const list: Shop[] = [];
    response.data.data.forEach((json: ShopJson) => {
      list.push(
        new Shop(
          json.id,
          json.salon_id,
          json.salon_name,
          json.name,
          json.kana,
          json.postcode,
          json.prefecture,
          json.city,
          json.address,
          json.building,
          json.telephone,
          json.staff_count,
          json.user_count,
          json.is_main,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      shops: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    salon_id: string,
    name: string,
    kana: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string
  ): Promise<Shop> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/shops`,
      {
        salon_id: salon_id,
        name: name,
        kana: kana,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
      },
      config
    );

    console.log(response);

    return new Shop(
      response.data.id,
      response.data.salon_id,
      response.data.salon_name,
      response.data.name,
      response.data.kana,
      response.data.postcode,
      response.data.prefecture,
      response.data.city,
      response.data.address,
      response.data.building,
      response.data.telephone,
      response.data.staff_count,
      response.data.user_count,
      response.data.is_main,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async update(
    id: string,
    name: string,
    kana: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string
  ): Promise<Shop> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/shops/${id}`,
      {
        id,
        name,
        kana,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
      },
      config
    );

    console.log(response);

    return new Shop(
      response.data.id,
      response.data.salon_id,
      response.data.salon_name,
      response.data.name,
      response.data.kana,
      response.data.postcode,
      response.data.prefecture,
      response.data.city,
      response.data.address,
      response.data.building,
      response.data.telephone,
      response.data.staff_count,
      response.data.user_count,
      response.data.is_main,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Shop> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/shops/${id}`,
      config
    );

    console.log(response);

    return new Shop(
      response.data.id,
      response.data.salon_id,
      response.data.salon_name,
      response.data.name,
      response.data.kana,
      response.data.postcode,
      response.data.prefecture,
      response.data.city,
      response.data.address,
      response.data.building,
      response.data.telephone,
      response.data.staff_count,
      response.data.user_count,
      response.data.is_main,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }
}
