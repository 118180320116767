import { Auth } from "aws-amplify";
import axios from "axios";

import Bank from "@/models/Bank";
import Branch from "@/models/Branch";

interface AdministratorJson {
  id: string;
  role: string;
  salon_id: string | null;
  dealer_id: string | null;
  name: string;
  kana: string;
  email: string;
  is_main: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface AdministratorSearchResult {
  staffs: Administrator[];
  last_evaluated_key: string | null;
}

export default class Administrator {
  id: string;
  role: string;
  salon_id: string | null;
  dealer_id: string | null;
  name: string;
  kana: string;
  email: string;
  is_main: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    role: string,
    salon_id: string | null,
    dealer_id: string | null,
    name: string,
    kana: string,
    email: string,
    is_main: boolean,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.role = role;
    this.salon_id = salon_id;
    this.dealer_id = dealer_id;
    this.name = name;
    this.kana = kana;
    this.email = email;
    this.is_main = is_main;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Administrator> {
    console.log(`Administrator.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/administrators/${id}`,
      config
    );

    const json = response.data.data as AdministratorJson;

    return new Administrator(
      json.id,
      json.role,
      json.salon_id,
      json.dealer_id,
      json.name,
      json.kana,
      json.email,
      json.is_main,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async searchBySalonId(
    salon_id: string,
    keyword: string | null = null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<Administrator[]> {
    console.log(
      `Administrator.search(${salon_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        salon_id,
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/administrators`,
      config
    );

    const list: Administrator[] = [];
    response.data.data.forEach((json: AdministratorJson) => {
      list.push(
        new Administrator(
          json.id,
          json.role,
          json.salon_id,
          json.dealer_id,
          json.name,
          json.kana,
          json.email,
          json.is_main,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return list;
  }

  static async registerSalon(
    salon_id: string,
    name: string,
    kana: string,
    email: string
  ): Promise<Administrator> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/administrators`,
      {
        role: "salon",
        salon_id: salon_id,
        name: name,
        kana: kana,
        email: email,
      },
      config
    );

    console.log(response);

    const json = response.data;

    return new Administrator(
      json.id,
      json.role,
      json.salon_id,
      json.dealer_id,
      json.name,
      json.kana,
      json.email,
      json.is_main,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    name: string,
    kana: string
  ): Promise<Administrator> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/administrators/${id}`,
      {
        id,
        name,
        kana,
      },
      config
    );

    console.log(response);

    const json = response.data;

    return new Administrator(
      json.id,
      json.role,
      json.salon_id,
      json.dealer_id,
      json.name,
      json.kana,
      json.email,
      json.is_main,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Administrator> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/administrators/${id}`,
      config
    );

    console.log(response);

    const json = response.data;

    return new Administrator(
      json.id,
      json.role,
      json.salon_id,
      json.dealer_id,
      json.name,
      json.kana,
      json.email,
      json.is_main,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
