import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Auth, Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";

import store from "@/store";

import Signin from "../pages/Signin.vue";

import Home from "../pages/Home.vue";
import DealerList from "../pages/DealerList.vue";
import DealerDetail from "../pages/DealerDetail.vue";
import DealerEdit from "../pages/DealerEdit.vue";
import SalonList from "../pages/SalonList.vue";
import SalonDetail from "../pages/SalonDetail.vue";
import SalonEdit from "../pages/SalonEdit.vue";
import TrendList from "../pages/TrendList.vue";
import InformationList from "../pages/InformationList.vue";
import TopicDetail from "../pages/TopicDetail.vue";
import TopicEdit from "../pages/TopicEdit.vue";
import DesignList from "../pages/DesignList.vue";
import AppAdvertisementList from "../pages/AppAdvertisementList.vue";
import AdvertisementDetail from "../pages/AdvertisementDetail.vue";
import AdvertisementEdit from "../pages/AdvertisementEdit.vue";
import SystemAffeliateList from "../pages/SystemAffeliateList.vue";
import SystemAffeliateMonthList from "../pages/SystemAffeliateMonthList.vue";
import JackRequestList from "../pages/JackRequestList.vue";
import JackRequestEdit from "../pages/JackRequestEdit.vue";
import JackRequestDetail from "../pages/JackRequestDetail.vue";
import JackResponseDetail from "../pages/JackResponseDetail.vue";

import ContestList from "../pages/ContestList.vue";
import ContestEdit from "../pages/ContestEdit.vue";
import ContestEntryList from "../pages/ContestEntryList.vue";

import TrialList from "../pages/TrialList.vue";
import TrialDetail from "../pages/TrialDetail.vue";
import TrialEdit from "../pages/TrialEdit.vue";

import ShopList from "../pages/ShopList.vue";
import ShopEdit from "../pages/ShopEdit.vue";
import StaffList from "../pages/StaffList.vue";
import StaffEdit from "../pages/StaffEdit.vue";
import CustomerList from "../pages/CustomerList.vue";
import AdministratorList from "../pages/AdministratorList.vue";
import AdministratorEdit from "../pages/AdministratorEdit.vue";
import SalonTopicList from "../pages/SalonTopicList.vue";
import SalonAffeliateList from "../pages/SalonAffeliateList.vue";

import TermsOfUse from "../pages/TermsOfUse.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy.vue";
import Transactions from "../pages/Transactions.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dealers",
    name: "DealerList",
    component: DealerList,
  },
  {
    path: "/dealers/register",
    name: "DealerRegister",
    component: DealerEdit,
  },
  {
    path: "/dealers/:dealer_id",
    name: "DealerDetail",
    component: DealerDetail,
  },
  {
    path: "/dealers/:dealer_id/edit",
    name: "DealerEdit",
    component: DealerEdit,
  },
  {
    path: "/salons",
    name: "SalonList",
    component: SalonList,
  },
  {
    path: "/salons/register",
    name: "SalonRegister",
    component: SalonEdit,
  },
  {
    path: "/salons/:salon_id",
    name: "SalonDetail",
    component: SalonDetail,
  },
  {
    path: "/salons/:salon_id/edit",
    name: "SalonEdit",
    component: SalonEdit,
  },
  {
    path: "/customers",
    name: "CustomerList",
    component: Home,
  },
  {
    path: "/trends",
    name: "TrendList",
    component: TrendList,
  },
  {
    path: "/information",
    name: "InformationList",
    component: InformationList,
  },
  {
    path: "/topics/:topic_id",
    name: "TopicDetail",
    component: TopicDetail,
  },
  {
    path: "/topics/:type/:salon_id/register",
    name: "TopicRegister",
    component: TopicEdit,
  },
  {
    path: "/topics/:type/:salon_id/:topic_id/edit",
    name: "TopicEdit",
    component: TopicEdit,
  },
  {
    path: "/advertisements/app",
    name: "AppAdvertisementList",
    component: AppAdvertisementList,
  },
  {
    path: "/advertisements/web",
    name: "WebAdvertisermentList",
    component: Home,
  },
  {
    path: "/advertisements/:advertisement_id",
    name: "AdvertisementDetail",
    component: AdvertisementDetail,
  },
  {
    path: "/advertisements/:type/register",
    name: "AdvertisementRegister",
    component: AdvertisementEdit,
  },
  {
    path: "/advertisements/:type/:advertisement_id/edit",
    name: "AdvertisementEdit",
    component: AdvertisementEdit,
  },
  {
    path: "/affeliates/system",
    name: "SystemAffeliateList",
    component: SystemAffeliateList,
  },
  {
    path: "/affeliates/system/:id/:ym",
    name: "SystemAffeliateMonthList",
    component: SystemAffeliateMonthList,
  },
  {
    path: "/contests",
    name: "ContestList",
    component: ContestList,
  },
  {
    path: "/contests/register",
    name: "ContestRegister",
    component: ContestEdit,
  },
  {
    path: "/contests/:contest_id/edit",
    name: "ContestEdit",
    component: ContestEdit,
  },
  {
    path: "/contests/:contest_id/entries",
    name: "ContestEntryList",
    component: ContestEntryList,
  },
  {
    path: "/trials",
    name: "TrialList",
    component: TrialList,
  },
  {
    path: "/trials/register",
    name: "TrialRegister",
    component: TrialEdit,
  },
  {
    path: "/trials/:trial_id/detail",
    name: "TrialDetail",
    component: TrialDetail,
  },
  {
    path: "/trials/:trial_id/edit",
    name: "TrialEdit",
    component: TrialEdit,
  },
  {
    path: "/jackrequests",
    name: "JackRequestList",
    component: JackRequestList,
  },
  {
    path: "/jackrequests/register",
    name: "JackRequestRegister",
    component: JackRequestEdit,
  },
  {
    path: "/jackrequests/:jack_request_id/edit",
    name: "JackRequestEdit",
    component: JackRequestEdit,
  },
  {
    path: "/jackrequests/:jack_request_id",
    name: "JackRequestDetail",
    component: JackRequestDetail,
  },
  {
    path: "/jackrequests/:jack_request_id/response/:salon_id",
    name: "JackResponseDetail",
    component: JackResponseDetail,
  },
  {
    path: "/designs",
    name: "DesignList",
    component: DesignList,
  },

  {
    path: "/shops",
    name: "ShopList",
    component: ShopList,
    meta: { context: "salon" },
  },
  {
    path: "/salons/:salon_id/shops/register",
    name: "ShopRegister",
    component: ShopEdit,
  },
  {
    path: "/salons/:salon_id/shops/:shop_id/edit",
    name: "ShopEdit",
    component: ShopEdit,
  },
  {
    path: "/salons/:salon_id/administrators/:administrator_id/edit",
    name: "SystemAdministratorEdit",
    component: AdministratorEdit,
  },
  {
    path: "/salons/:salon_id/administrators/register",
    name: "SystemAdministratorRegister",
    component: AdministratorEdit,
  },
  {
    path: "/staffs",
    name: "StaffList",
    component: StaffList,
    meta: { context: "salon" },
  },
  {
    path: "/staffs/register",
    name: "StaffRegister",
    component: StaffEdit,
    meta: { context: "salon" },
  },
  {
    path: "/staffs/:staff_id/edit",
    name: "StaffEdit",
    component: StaffEdit,
    meta: { context: "salon" },
  },
  {
    path: "/customers",
    name: "CustomerList",
    component: CustomerList,
    meta: { context: "salon" },
  },
  {
    path: "/administrators",
    name: "AdministratorList",
    component: AdministratorList,
    meta: { context: "salon" },
  },
  {
    path: "/administrators/register",
    name: "SalonAdministratorRegister",
    component: AdministratorEdit,
    meta: { context: "salon" },
  },
  {
    path: "/administrators/:administrator_id/edit",
    name: "SalonAdministratorEdit",
    component: AdministratorEdit,
    meta: { context: "salon" },
  },
  {
    path: "/salontopics",
    name: "SalonTopicList",
    component: SalonTopicList,
    meta: { context: "salon" },
  },
  {
    path: "/affeliates/salon",
    name: "SalonAffeliateList",
    component: SalonAffeliateList,
  },

  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { context: "auth" },
  },
  {
    path: "/termsofuse",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: { context: "auth" },
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    meta: { context: "auth" },
  },

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: { context: "auth" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userInfo = await Auth.currentUserInfo();

  if (
    to.fullPath == "/termsofuse" ||
    to.fullPath == "/privacypolicy" ||
    to.fullPath == "/transactions"
  ) {
    next();
    return;
  }

  if (!userInfo && to.fullPath !== "/signin") {
    next("/signin");
    return;
  }

  if (userInfo && to.fullPath == "/signin") {
    next("/");
    return;
  }

  next();
});

const authListener: HubCallback = ({ payload: { event /*, data */ } }) => {
  console.log(event);
  switch (event) {
    case "signIn":
      // setUser({
      //   username: data.username,
      //   token: data.signInUserSession.accessToken,
      // });
      store.dispatch("signIn");
      router.replace("/");
      break;
    case "signOut":
      router.replace("/signin");
      break;
  }
};

Hub.listen("auth", authListener);

export default router;
