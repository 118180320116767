import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface JackRequestJson {
  id: string;
  title: string;
  article: string;
  condition1: string | null;
  condition2: string | null;
  condition3: string | null;
  condition4: string | null;
  condition5: string | null;
  condition6: string | null;
  condition7: string | null;
  condition8: string | null;
  condition9: string | null;
  condition10: string | null;
  condition11: string | null;
  condition12: string | null;
  condition13: string | null;
  condition14: string | null;
  condition15: string | null;
  replyment_deadline: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface JackRequestSearchResult {
  requests: JackRequest[];
  last_evaluated_key: string | null;
}

export default class JackRequest {
  id: string;
  title: string;
  article: string;
  condition1: string | null;
  condition2: string | null;
  condition3: string | null;
  condition4: string | null;
  condition5: string | null;
  condition6: string | null;
  condition7: string | null;
  condition8: string | null;
  condition9: string | null;
  condition10: string | null;
  condition11: string | null;
  condition12: string | null;
  condition13: string | null;
  condition14: string | null;
  condition15: string | null;
  replyment_deadline: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  get isExpired(): boolean {
    return moment(this.replyment_deadline).diff(moment(), "days") < 0;
  }

  constructor(
    id: string,
    title: string,
    article: string,
    condition1: string | null,
    condition2: string | null,
    condition3: string | null,
    condition4: string | null,
    condition5: string | null,
    condition6: string | null,
    condition7: string | null,
    condition8: string | null,
    condition9: string | null,
    condition10: string | null,
    condition11: string | null,
    condition12: string | null,
    condition13: string | null,
    condition14: string | null,
    condition15: string | null,
    replyment_deadline: Date,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.title = title;
    this.article = article;
    this.condition1 = condition1;
    this.condition2 = condition2;
    this.condition3 = condition3;
    this.condition4 = condition4;
    this.condition5 = condition5;
    this.condition6 = condition6;
    this.condition7 = condition7;
    this.condition8 = condition8;
    this.condition9 = condition9;
    this.condition10 = condition10;
    this.condition11 = condition11;
    this.condition12 = condition12;
    this.condition13 = condition13;
    this.condition14 = condition14;
    this.condition15 = condition15;
    this.replyment_deadline = replyment_deadline;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<JackRequest> {
    console.log(`JackRequest.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/jackrequests/${id}`,
      config
    );

    const json = response.data.data as JackRequestJson;

    return new JackRequest(
      json.id,
      json.title,
      json.article,
      json.condition1,
      json.condition2,
      json.condition3,
      json.condition4,
      json.condition5,
      json.condition6,
      json.condition7,
      json.condition8,
      json.condition9,
      json.condition10,
      json.condition11,
      json.condition12,
      json.condition13,
      json.condition14,
      json.condition15,
      new Date(json.replyment_deadline),
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<JackRequestSearchResult> {
    console.log(`JackRequest.search(${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/jackrequests`,
      config
    );

    const list: JackRequest[] = [];
    response.data.data.forEach((json: JackRequestJson) => {
      list.push(
        new JackRequest(
          json.id,
          json.title,
          json.article,
          json.condition1,
          json.condition2,
          json.condition3,
          json.condition4,
          json.condition5,
          json.condition6,
          json.condition7,
          json.condition8,
          json.condition9,
          json.condition10,
          json.condition11,
          json.condition12,
          json.condition13,
          json.condition14,
          json.condition15,
          new Date(json.replyment_deadline),
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      requests: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    title: string,
    article: string,
    condition1: string | null,
    condition2: string | null,
    condition3: string | null,
    condition4: string | null,
    condition5: string | null,
    condition6: string | null,
    condition7: string | null,
    condition8: string | null,
    condition9: string | null,
    condition10: string | null,
    condition11: string | null,
    condition12: string | null,
    condition13: string | null,
    condition14: string | null,
    condition15: string | null,
    replyment_deadline: Date
  ): Promise<JackRequest> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/jackrequests`,
      {
        title,
        article,
        condition1,
        condition2,
        condition3,
        condition4,
        condition5,
        condition6,
        condition7,
        condition8,
        condition9,
        condition10,
        condition11,
        condition12,
        condition13,
        condition14,
        condition15,
        replyment_deadline: moment(replyment_deadline).format("YYYY-MM-DD"),
      },
      config
    );

    console.log(response);

    const json = response.data as JackRequestJson;

    return new JackRequest(
      json.id,
      json.title,
      json.article,
      json.condition1,
      json.condition2,
      json.condition3,
      json.condition4,
      json.condition5,
      json.condition6,
      json.condition7,
      json.condition8,
      json.condition9,
      json.condition10,
      json.condition11,
      json.condition12,
      json.condition13,
      json.condition14,
      json.condition15,
      new Date(json.replyment_deadline),
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    title: string,
    article: string,
    condition1: string | null,
    condition2: string | null,
    condition3: string | null,
    condition4: string | null,
    condition5: string | null,
    condition6: string | null,
    condition7: string | null,
    condition8: string | null,
    condition9: string | null,
    condition10: string | null,
    condition11: string | null,
    condition12: string | null,
    condition13: string | null,
    condition14: string | null,
    condition15: string | null,
    replyment_deadline: Date
  ): Promise<JackRequest> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/jackrequests/${id}`,
      {
        id,
        title,
        article,
        condition1,
        condition2,
        condition3,
        condition4,
        condition5,
        condition6,
        condition7,
        condition8,
        condition9,
        condition10,
        condition11,
        condition12,
        condition13,
        condition14,
        condition15,
        replyment_deadline: moment(replyment_deadline).format("YYYY-MM-DD"),
      },
      config
    );

    console.log(response);

    const json = response.data as JackRequestJson;

    return new JackRequest(
      json.id,
      json.title,
      json.article,
      json.condition1,
      json.condition2,
      json.condition3,
      json.condition4,
      json.condition5,
      json.condition6,
      json.condition7,
      json.condition8,
      json.condition9,
      json.condition10,
      json.condition11,
      json.condition12,
      json.condition13,
      json.condition14,
      json.condition15,
      new Date(json.replyment_deadline),
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<JackRequest> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/jackrequests/${id}`,
      config
    );

    console.log(response);

    const json = response.data as JackRequestJson;

    return new JackRequest(
      json.id,
      json.title,
      json.article,
      json.condition1,
      json.condition2,
      json.condition3,
      json.condition4,
      json.condition5,
      json.condition6,
      json.condition7,
      json.condition8,
      json.condition9,
      json.condition10,
      json.condition11,
      json.condition12,
      json.condition13,
      json.condition14,
      json.condition15,
      new Date(json.replyment_deadline),
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
