import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface JackReplymentJson {
  id: string;
  request_id: string;
  salon_id: string;
  salon_name: string;
  replyment_body: string;
  answer1: string | null;
  answer2: string | null;
  answer3: string | null;
  answer4: string | null;
  answer5: string | null;
  answer6: string | null;
  answer7: string | null;
  answer8: string | null;
  answer9: string | null;
  answer10: string | null;
  answer11: string | null;
  answer12: string | null;
  answer13: string | null;
  answer14: string | null;
  answer15: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface JackReplymentSearchResult {
  replyments: JackReplyment[];
  last_evaluated_key: string | null;
}

export default class JackReplyment {
  id: string;
  request_id: string;
  salon_id: string;
  salon_name: string;
  replyment_body: string;
  answer1: string | null;
  answer2: string | null;
  answer3: string | null;
  answer4: string | null;
  answer5: string | null;
  answer6: string | null;
  answer7: string | null;
  answer8: string | null;
  answer9: string | null;
  answer10: string | null;
  answer11: string | null;
  answer12: string | null;
  answer13: string | null;
  answer14: string | null;
  answer15: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    request_id: string,
    salon_id: string,
    salon_name: string,
    replyment_body: string,
    answer1: string | null,
    answer2: string | null,
    answer3: string | null,
    answer4: string | null,
    answer5: string | null,
    answer6: string | null,
    answer7: string | null,
    answer8: string | null,
    answer9: string | null,
    answer10: string | null,
    answer11: string | null,
    answer12: string | null,
    answer13: string | null,
    answer14: string | null,
    answer15: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.request_id = request_id;
    this.salon_id = salon_id;
    this.salon_name = salon_name;
    this.replyment_body = replyment_body;
    this.answer1 = answer1;
    this.answer2 = answer2;
    this.answer3 = answer3;
    this.answer4 = answer4;
    this.answer5 = answer5;
    this.answer6 = answer6;
    this.answer7 = answer7;
    this.answer8 = answer8;
    this.answer9 = answer9;
    this.answer10 = answer10;
    this.answer11 = answer11;
    this.answer12 = answer12;
    this.answer13 = answer13;
    this.answer14 = answer14;
    this.answer15 = answer15;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(
    request_id: string,
    salon_id: string
  ): Promise<JackReplyment | null> {
    console.log(`JackReplyment.get(${request_id}, ${salon_id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/jackreplyments/${request_id}_${salon_id}`,
      config
    );

    if (response.data.code == 404) {
      return null;
    }

    const json = response.data.data as JackReplymentJson;

    return new JackReplyment(
      json.id,
      json.request_id,
      json.salon_id,
      json.salon_name,
      json.replyment_body,
      json.answer1,
      json.answer2,
      json.answer3,
      json.answer4,
      json.answer5,
      json.answer6,
      json.answer7,
      json.answer8,
      json.answer9,
      json.answer10,
      json.answer11,
      json.answer12,
      json.answer13,
      json.answer14,
      json.answer15,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    request_id: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<JackReplymentSearchResult> {
    console.log(
      `JackReplyment.search(${request_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        request_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/jackreplyments`,
      config
    );

    const list: JackReplyment[] = [];
    response.data.data.forEach((json: JackReplymentJson) => {
      list.push(
        new JackReplyment(
          json.id,
          json.request_id,
          json.salon_id,
          json.salon_name,
          json.replyment_body,
          json.answer1,
          json.answer2,
          json.answer3,
          json.answer4,
          json.answer5,
          json.answer6,
          json.answer7,
          json.answer8,
          json.answer9,
          json.answer10,
          json.answer11,
          json.answer12,
          json.answer13,
          json.answer14,
          json.answer15,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      replyments: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    request_id: string,
    salon_id: string,
    replyment_body: string,
    answer1: string | null,
    answer2: string | null,
    answer3: string | null,
    answer4: string | null,
    answer5: string | null,
    answer6: string | null,
    answer7: string | null,
    answer8: string | null,
    answer9: string | null,
    answer10: string | null,
    answer11: string | null,
    answer12: string | null,
    answer13: string | null,
    answer14: string | null,
    answer15: string | null
  ): Promise<JackReplyment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/jackreplyments`,
      {
        request_id,
        salon_id,
        replyment_body,
        answer1,
        answer2,
        answer3,
        answer4,
        answer5,
        answer6,
        answer7,
        answer8,
        answer9,
        answer10,
        answer11,
        answer12,
        answer13,
        answer14,
        answer15,
      },
      config
    );

    console.log(response);

    const json = response.data as JackReplymentJson;

    return new JackReplyment(
      json.id,
      json.request_id,
      json.salon_id,
      json.salon_name,
      json.replyment_body,
      json.answer1,
      json.answer2,
      json.answer3,
      json.answer4,
      json.answer5,
      json.answer6,
      json.answer7,
      json.answer8,
      json.answer9,
      json.answer10,
      json.answer11,
      json.answer12,
      json.answer13,
      json.answer14,
      json.answer15,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    request_id: string,
    salon_id: string,
    replyment_body: string,
    answer1: string | null,
    answer2: string | null,
    answer3: string | null,
    answer4: string | null,
    answer5: string | null,
    answer6: string | null,
    answer7: string | null,
    answer8: string | null,
    answer9: string | null,
    answer10: string | null,
    answer11: string | null,
    answer12: string | null,
    answer13: string | null,
    answer14: string | null,
    answer15: string | null
  ): Promise<JackReplyment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/jackreplyments/${id}`,
      {
        id,
        request_id,
        salon_id,
        replyment_body,
        answer1,
        answer2,
        answer3,
        answer4,
        answer5,
        answer6,
        answer7,
        answer8,
        answer9,
        answer10,
        answer11,
        answer12,
        answer13,
        answer14,
        answer15,
      },
      config
    );

    console.log(response);

    const json = response.data as JackReplymentJson;

    return new JackReplyment(
      json.id,
      json.request_id,
      json.salon_id,
      json.salon_name,
      json.replyment_body,
      json.answer1,
      json.answer2,
      json.answer3,
      json.answer4,
      json.answer5,
      json.answer6,
      json.answer7,
      json.answer8,
      json.answer9,
      json.answer10,
      json.answer11,
      json.answer12,
      json.answer13,
      json.answer14,
      json.answer15,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<JackReplyment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/jackreplyments/${id}`,
      config
    );

    console.log(response);

    const json = response.data as JackReplymentJson;

    return new JackReplyment(
      json.id,
      json.request_id,
      json.salon_id,
      json.salon_name,
      json.replyment_body,
      json.answer1,
      json.answer2,
      json.answer3,
      json.answer4,
      json.answer5,
      json.answer6,
      json.answer7,
      json.answer8,
      json.answer9,
      json.answer10,
      json.answer11,
      json.answer12,
      json.answer13,
      json.answer14,
      json.answer15,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
