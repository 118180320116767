
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    message: String,
  },

  methods: {
    onOk() {
      this.$emit("ok");
    },

    onCancel() {
      this.$emit("cancel");
    },
  },
});
