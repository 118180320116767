
import { defineComponent } from "vue";

import Dealer from "@/models/Dealer";
// import Shop from "@/models/Shop";
// import Staff from "@/models/Staff";

interface DealerDetailState {
  // is_loading: boolean;

  dealer: Dealer;
  // shops: Shop[];
  // staffs: Staff[];

  selected_tab: string;
}

export default defineComponent({
  data() {
    return {
      // is_loading: false,

      dealer: {} as Dealer,
    } as DealerDetailState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatAccountType(type: string) {
      switch (type) {
        case "normal":
          return "普通";
        case "checking":
          return "当座";
        case "deposit":
          return "貯蓄";
        default:
          return "";
      }
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      const id = this.$route.params.dealer_id as string;

      setTimeout(async () => {
        try {
          this.dealer = await Dealer.get(id);
          // const result1 = await Shop.search(id);
          // this.shops = result1.shops;
          // console.log(this.shops);

          // const result2 = await Staff.searchByDealerId(id);
          // this.staffs = result2.staffs;
          // console.log(this.shops);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },
  },
});
