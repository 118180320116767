<template>
  <div>
    <pre>
個人情報保護基本方針（プライバシーポリシー）
株式会社SALON MEDIA（以下「当社」という。）は個人情報保護の重要性を深く認識し、個人情報保護に向けての取り組みの基本方針を定め、以下の方針に基づき個人情報の保護に努めます。
当社への個人情報の提供にあたっては、下記の内容にご同意の上、ご提供頂きますようにお願いします。
第1条（個人情報の収集）
１．当社は、適正かつ公正な手段によって、個人情報を収集します。
２．インターネット経由で取得した個人情報はSSLにて暗号化され、サーバーに送信されます。
３．機微な個人情報（センシティブ情報）については、当社の業務の遂行上必要な範囲外のものを取得しません。
４．当社が個人情報を収集するときは、以下の目的の範囲内で、業務の遂行上必要な限りにおいて利用します。
（１）利用者に対する情報の提供、利用者情報の管理及び、依頼、問い合わせへの対応などの業務の遂行。
（２）当社サービスに関する営業上のご案内。
（３）サービスの企画開発あるいは利用者満足度向上のためのアンケート調査等。
（４）法令による定めまたは行政当局の通達・指導などに基づく対応。
上記の利用目的は、相当の関連性を有すると合理的に認められる範囲内で変更することがあります。上記の利用目的を変更する場合には、その内容を当社のホームページ等により、公表します。

第2条（個人情報の第三者提供）
当社は、法令に定める場合等、正当な理由のない限り個人情報を本人の同意を得ることなく、第三者に提供しません。
ただし、以下の場合は利用者のご同意なく個人データを開示提供する場合があります。

（１）法令に基づく場合。
（２）人の生命、身体または財産〈法人の財産を含む〉の保護のために必要がある場合であって、 利用者のご同意を得ることが困難であるとき。
（３）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、 利用者のご同意を得ることが困難であるとき。
（４）国の機関もしくは地方公共団体またはその委託を受けた者が法令に定める事務を遂行することに対して協力する必要がある場合であって、利用者のご同意を得ることにより当該事務遂行に支障を及ぼす恐れがあるとき。
（５）通信販売の業務を遂行する為、販売元へ情報を提供。
第3条（外部委託）
当社は、情報処理などの業務を外部に委託する際に、個人情報を提供する場合があります。個人情報の提供を行う場合には、委託先が個人情報保護体制を確保していることを条件とします。

第4条（個人データの安全管理に関する基本方針）
当社は、お客様の個人情報について、適切な安全措置を講ずることにより、個人情報の紛失、改ざんおよび漏えいなどの危険防止に努めます。

第5条（個人情報の取扱いに関する苦情、相談対応）
当社は、個人情報保護のための関連法令およびその他の規範を遵守します。当社は、個人情報の取扱いに関する苦情・相談などの敏速な対応に努めます。


問い合わせ先

〒８１２－００２６

福岡市博多区上川端町１２－２８安田第１ビル３階

電話：092-409-7158

担当：福島和人

第6条（基本方針の継続的改善）
当社は、個人情報の取扱いに関して、定期的に監査を行い、常に継続的改善に努めます。

第7条（規約の変更）
当社は、必要に応じて、個人情報保護基本方針（プライバシーポリシー）の内容を変更することができるものとします。変更時は、ホームページ上に変更の内容を表示します。

（附則） 2018年3月1日施行
    </pre>
  </div>
</template>
