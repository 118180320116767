
import { defineComponent } from "vue";
import moment from "moment";
import Salon from "@/models/Salon";
import Topic from "@/models/Topic";

interface HomeState {
  salon: Salon;
  topics: Topic[];
  last_evaluated_key: string | null;
}

export default defineComponent({
  data() {
    return {
      salon: {} as Salon,
      topics: [],
      last_evaluated_key: null,
    } as HomeState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          console.log(this.$store.state.salon_id);
          if(this.$store.state.salon_id) {
            const result1 = await Salon.get(this.$store.state.salon_id);
            this.salon = result1;
          }
          const result2 = await Topic.searchInformation(
            null,
            this.last_evaluated_key,
            20
          );
          this.topics = result2.topics;
          this.last_evaluated_key = result2.last_evaluated_key;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },
  },
});
