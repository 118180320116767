import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface AdvertisementJson {
  id: string;
  type: string;
  title: string;
  redirect_url: string;
  body: string;
  publish_from: Date | null;
  publish_to: Date | null;
  target_age_from: number | null;
  target_age_to: number | null;
  target_genders: string[] | null;
  target_consumptions: string[] | null;
  target_prefectures: string[] | null;
  show_detail_page: boolean;
  view_count: number;
  click_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface AdvertisementSearchResult {
  advertisements: Advertisement[];
  last_evaluated_key: string | null;
}

export default class Advertisement {
  id: string;
  type: string;
  title: string;
  redirect_url: string;
  body: string;
  publish_from: Date | null;
  publish_to: Date | null;
  target_age_from: number | null;
  target_age_to: number | null;
  target_genders: string[] | null;
  target_consumptions: string[] | null;
  target_prefectures: string[] | null;
  show_detail_page: boolean;
  view_count: number;
  click_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    type: string,
    title: string,
    redirect_url: string,
    body: string,
    publish_from: Date | null,
    publish_to: Date | null,
    target_age_from: number | null,
    target_age_to: number | null,
    target_genders: string[] | null,
    target_consumptions: string[] | null,
    target_prefectures: string[] | null,
    show_detail_page: boolean,
    view_count: number,
    click_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.redirect_url = redirect_url;
    this.body = body;
    this.publish_from = publish_from;
    this.publish_to = publish_to;
    this.target_age_from = target_age_from;
    this.target_age_to = target_age_to;
    this.target_genders = target_genders;
    this.target_consumptions = target_consumptions;
    this.target_prefectures = target_prefectures;
    this.show_detail_page = show_detail_page;
    this.view_count = view_count;
    this.click_count = click_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Advertisement> {
    console.log(`Advertisement.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/promos/${id}`,
      config
    );

    const json = response.data.data as AdvertisementJson;

    return new Advertisement(
      json.id,
      json.type,
      json.title,
      json.redirect_url,
      json.body,
      json.publish_from ? new Date(json.publish_from) : null,
      json.publish_to ? new Date(json.publish_to) : null,
      json.target_age_from,
      json.target_age_to,
      json.target_genders,
      json.target_consumptions,
      json.target_prefectures,
      json.show_detail_page,
      json.view_count,
      json.click_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async searchAppAdvertisement(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<AdvertisementSearchResult> {
    console.log(
      `Advertisement.searchAppAdvertisement(${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        type: "app",
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/promos`,
      config
    );

    const list: Advertisement[] = [];
    response.data.data.forEach((json: AdvertisementJson) => {
      list.push(
        new Advertisement(
          json.id,
          json.type,
          json.title,
          json.redirect_url,
          json.body,
          json.publish_from ? new Date(json.publish_from) : null,
          json.publish_to ? new Date(json.publish_to) : null,
          json.target_age_from,
          json.target_age_to,
          json.target_genders,
          json.target_consumptions,
          json.target_prefectures,
          json.show_detail_page,
          json.view_count,
          json.click_count,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      advertisements: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    type: string,
    title: string,
    redirect_url: string,
    body: string,
    publish_from: Date | null,
    publish_to: Date | null,
    target_age_from: number | null,
    target_age_to: number | null,
    target_genders: string[] | null,
    target_consumptions: string[] | null,
    target_prefectures: string[] | null,
    show_detail_page: boolean
  ): Promise<Advertisement> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/promos`,
      {
        type,
        title,
        redirect_url,
        body,
        publish_from: publish_from
          ? moment(publish_from).format("YYYY-MM-DD")
          : null,
        publish_to: publish_to ? moment(publish_to).format("YYYY-MM-DD") : null,
        target_age_from,
        target_age_to,
        target_genders,
        target_consumptions,
        target_prefectures,
        show_detail_page,
      },
      config
    );

    console.log(response);

    return new Advertisement(
      response.data.id,
      response.data.type,
      response.data.title,
      response.data.redirect_url,
      response.data.body,
      response.data.publish_from ? new Date(response.data.publish_from) : null,
      response.data.publish_to ? new Date(response.data.publish_to) : null,
      response.data.target_age_from,
      response.data.target_age_to,
      response.data.target_genders,
      response.data.target_consumptions,
      response.data.target_prefectures,
      response.data.show_detail_page,
      response.data.view_count,
      response.data.click_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async update(
    id: string,
    title: string,
    redirect_url: string,
    body: string,
    publish_from: Date | null,
    publish_to: Date | null,
    target_age_from: number | null,
    target_age_to: number | null,
    target_genders: string[] | null,
    target_consumptions: string[] | null,
    target_prefectures: string[] | null,
    show_detail_page: boolean
  ): Promise<Advertisement> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/promos/${id}`,
      {
        id,
        title,
        redirect_url,
        body,
        publish_from: publish_from
          ? moment(publish_from).format("YYYY-MM-DD")
          : null,
        publish_to: publish_to ? moment(publish_to).format("YYYY-MM-DD") : null,
        target_age_from,
        target_age_to,
        target_genders,
        target_consumptions,
        target_prefectures,
        show_detail_page,
      },
      config
    );

    console.log(response);

    return new Advertisement(
      response.data.id,
      response.data.type,
      response.data.title,
      response.data.redirect_url,
      response.data.body,
      response.data.publish_from ? new Date(response.data.publish_from) : null,
      response.data.publish_to ? new Date(response.data.publish_to) : null,
      response.data.target_age_from,
      response.data.target_age_to,
      response.data.target_genders,
      response.data.target_consumptions,
      response.data.target_prefectures,
      response.data.show_detail_page,
      response.data.view_count,
      response.data.click_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Advertisement> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/promos/${id}`,
      config
    );

    console.log(response);

    return new Advertisement(
      response.data.id,
      response.data.type,
      response.data.title,
      response.data.redirect_url,
      response.data.body,
      response.data.publish_from ? new Date(response.data.publish_from) : null,
      response.data.publish_to ? new Date(response.data.publish_to) : null,
      response.data.target_age_from,
      response.data.target_age_to,
      response.data.target_genders,
      response.data.target_consumptions,
      response.data.target_prefectures,
      response.data.show_detail_page,
      response.data.view_count,
      response.data.click_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }
}
