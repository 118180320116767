
import { defineComponent } from "vue";
import moment from "moment";
import encoding from "encoding-japanese";

import AffeliateDaily from "@/models/AffeliateDaily";
import AffeliateMonthly from "@/models/AffeliateMonthly";
import Sales from "@/models/Sales";

interface SystemAffeliateListState {
  // is_loading: boolean;

  ym: string[];
  selected_ym: string;

  affeliates: AffeliateDaily[];
  last_evaluated_key: string | null;

  selected_tab: string;
}

interface SalonCsvItem {
  id: string;
  name: string;
  salon_price: number;
  salon_tax: number;
  salon_price_with_tax: number;
  staff_price: number;
  staff_tax: number;
  staff_price_with_tax: number;
  price: number;
  tax: number;
  price_with_tax: number;
}

const PAGE_SIZE = 20;

export default defineComponent({
  data() {
    const dt = moment();

    const selected_ym = dt.format("YYYY-MM");

    const ym: string[] = [];
    while (dt.format("YYYYMM") != "202101") {
      ym.push(dt.format("YYYY-MM"));

      dt.add(-1, "months");
    }

    return {
      // is_loading: false,

      ym: ym,
      selected_ym: selected_ym,

      affeliates: [],
      last_evaluated_key: null,

      selected_tab: "salomedi",
    } as SystemAffeliateListState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatYmd(ymd: number, format: string) {
      return moment(
        `${ymd.toString().substring(0, 4)}-${ymd
          .toString()
          .substring(4, 6)}-${ymd.toString().substring(6, 8)}`
      ).format(format);
    },

    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const dt = moment(`${this.selected_ym}-01`);

          const result = await AffeliateDaily.search(
            this.selected_tab,
            null,
            parseInt(dt.format("YYYYMM")),
            this.last_evaluated_key,
            PAGE_SIZE
          );
          this.affeliates = result.affeliates;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onSelectedYmChanged() {
      this.refresh();
    },

    changeTab(tab: string) {
      this.selected_tab = tab;
      this.refresh();
    },

    downloadCsv() {
      if (this.selected_tab == "dealer") {
        this._downloadDealerCsv();
      } else if (this.selected_tab == "salon") {
        this._downloadSalonCsv();
      }
    },

    _downloadDealerCsv() {
      setTimeout(async () => {
        const ym = parseInt(this.selected_ym.replaceAll("-", ""));
        const sales = await Sales.get(ym);

        const m1 = await AffeliateMonthly.search(
          "salomedi",
          null,
          ym,
          null,
          null
        );
        // const m2 = await AffeliateMonthly.search(
        //   "dealer",
        //   null,
        //   ym,
        //   null,
        //   null
        // );
        const m3 = await AffeliateMonthly.search("salon", null, ym, null, null);
        const m4 = await AffeliateMonthly.search("staff", null, ym, null, null);

        let systemTotal = 0;
        // let dealerTotal = 0;
        let salonTotal = 0;
        let staffTotal = 0;

        if (m1.affeliates) {
          m1.affeliates.forEach((item) => {
            systemTotal += item.price;
          });
        }
        // if (m2.affeliates) {
        //   m2.affeliates.forEach((item) => {
        //     dealerTotal += item.price;
        //   });
        // }
        if (m3.affeliates) {
          m3.affeliates.forEach((item) => {
            salonTotal += item.price;
          });
        }
        if (m4.affeliates) {
          m4.affeliates.forEach((item) => {
            staffTotal += item.price;
          });
        }

        const data = `売上,システムアフェリエイト額,サロンアフェリエイト額,スタッフアフェリエイト額,支払額\r\n${
          sales.total_price || 0
        },${systemTotal || 0},${salonTotal || 0},${staffTotal || 0},${
          sales.total_price - systemTotal - salonTotal - staffTotal || 0
        }`;

        const encoded = new TextEncoder().encode(data);
        const detected = encoding.detect(encoded);
        const codeArray = encoding.convert(encoded, {
          from: detected,
          to: "SJIS",
        });
        const uint8Array = new Uint8Array(codeArray);
        const mimeType = "text/css";
        const fileName = "output.csv";
        const blob = new Blob([uint8Array], { type: mimeType });

        if (window.URL || window.webkitURL) {
          // Chrome Safari FireFox
          window.URL = window.URL || window.webkitURL;
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;

          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );

          // Safari < 10.1 or 10.2 の場合は _blank必須
          if (isSafari) {
            a.target = "_blank";
          }

          a.click();
        }
      });
    },

    _downloadSalonCsv() {
      setTimeout(async () => {
        const ym = parseInt(this.selected_ym.replaceAll("-", ""));
        const sales = await Sales.get(ym);

        const m1 = await AffeliateMonthly.search(
          "salomedi",
          null,
          ym,
          null,
          null
        );
        // const m2 = await AffeliateMonthly.search(
        //   "dealer",
        //   null,
        //   ym,
        //   null,
        //   null
        // );
        const m3 = await AffeliateMonthly.search("salon", null, ym, null, null);
        const m4 = await AffeliateMonthly.search("staff", null, ym, null, null);

        const list: SalonCsvItem[] = [];

        if (m3.affeliates) {
          m3.affeliates.forEach((element) => {
            console.log(element);
            let item: SalonCsvItem | undefined = list.find(
              (check) => check.id == element.salon_id
            );

            if (!item) {
              item = {
                id: element.target_id,
                name: element.target_name,
                salon_price: 0,
                salon_tax: 0,
                salon_price_with_tax: 0,
                staff_price: 0,
                staff_tax: 0,
                staff_price_with_tax: 0,
                price: 0,
                tax: 0,
                price_with_tax: 0,
              };

              list.push(item);
            }

            item.salon_price += element.price;
            item.salon_tax += element.tax;
            item.salon_price_with_tax += element.price + element.tax;
            item.price += element.price;
            item.tax += element.tax;
            item.price_with_tax += element.price + element.tax;
          });
        }
        if (m4.affeliates) {
          m4.affeliates.forEach((element) => {
            console.log(element);
            const item: SalonCsvItem | undefined = list.find(
              (check) => check.id == element.salon_id
            );

            if (!item) {
              return;
            }

            item.staff_price += element.price;
            item.staff_tax += element.tax;
            item.staff_price_with_tax += element.price + element.tax;
            item.price += element.price;
            item.tax += element.tax;
            item.price_with_tax += element.price + element.tax;
          });
        }

        let data = `サロンコード,サロン名,サロン報酬額,サロン消費税,サロン報酬額(税込),スタッフ報酬額,スタッフ消費税,スタッフ報酬額(税込),報酬額,消費税,報酬額(税込)`;

        list.forEach((item) => {
          data += "\r\n";

          data += `${item.id},${item.name},${item.salon_price},${item.salon_tax},${item.salon_price_with_tax},${item.staff_price},${item.staff_tax},${item.staff_price_with_tax},${item.price},${item.tax},${item.price_with_tax}`;
        });

        const encoded = new TextEncoder().encode(data);
        const detected = encoding.detect(encoded);
        const codeArray = encoding.convert(encoded, {
          from: detected,
          to: "SJIS",
        });
        const uint8Array = new Uint8Array(codeArray);
        const mimeType = "text/css";
        const fileName = "output.csv";
        const blob = new Blob([uint8Array], { type: mimeType });

        if (window.URL || window.webkitURL) {
          // Chrome Safari FireFox
          window.URL = window.URL || window.webkitURL;
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;

          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );

          // Safari < 10.1 or 10.2 の場合は _blank必須
          if (isSafari) {
            a.target = "_blank";
          }

          a.click();
        }
      });
    },
  },
});
