<template>
  <div>
    <pre>
利用規約
この規約は（以下「本規約」といいます。）、株式会社SALON MEDIA（以下「当社」といいます。）が提供するアプリケーション及び関連ソフトウェア（以下、併せて「本アプリ」といいます。）の利用の条件を定めたものです。 利用者が本アプリをご利用頂くためには、本規約に同意し、遵守して頂くことが前提となります。本規約に同意した上で、本アプリを利用してください。本規約に同意頂けない場合は、本アプリを利用することはできませんので、その場合は直ちに利用を中止してください。利用者が本規約に同意した時点で、利用者と当社の間で、本規約を条件とした利用契約が成立します。 本規約の内容は、必要に応じて、事前の予告なしに変更されることがありますので、ご利用の継続に際しては、本規約の最新の内容をご確認下さい。本規約の変更後も、本アプリをご利用された時点で、利用者が本規約の変更に同意いただいたものとみなします。 なお、利用者が未成年者の場合は、本規約に対して親権者等の法定代理人の同意を得た上で、本アプリを利用するものとします。
第１章 総則
第1条（定義）
本規約における以下の言葉の意味を明確化します。
（１）「当社」とは、株式会社SALON MEDIAのことです。
（２）「本アプリ」とは、「当社」が提供するアプリケーション及びソフトウェアのことです。
（３）「本サービス」とは、「本アプリ」上及び「本アプリ」に付随して、提供されるサービスのことです。
（４）「利用者」とは、「本アプリ」を利用し、または利用しようとする方です。
（５）「デバイス」とは、「本アプリ」を導入可能なスマートフォン等の携帯端末その他の電子機器のことです。
第2条（適用範囲）
１．本規約は、利用者のすべてに適用されます。
２．当社が本規約と別に規約やガイドランなど（以下「個別規約等」といいます。）を定めた場合は、個別規約等と本規約は、一体を成し適用されます。
３．本規約と個別規約等の規定が異なる場合は、個別規約等の規定が優先適用されます。
第3条（利用条件）
１．本アプリは、著作権法及び著作権に関する条約をはじめ、その他知的財産権に関する法律及び条約によって保護されています。利用者は、本利用契約期間中、本アプリを非独占的に利用者自身の為に使用することを許諾されます（以下「使用許諾権」といいます）。
２．利用者は、有償・無償に関わらず、本アプリの使用許諾権を第三者に譲渡または転貸したり、プログラムを開示したりまたは複写したりすることはできません。
３．本アプリは、利用者が個人で使用する目的でのみ利用することができ、販売、配布、一般公開及び開発目的など他の目的で使用することはできません。
４．利用者は、本アプリについて、変更・改良行為や、リバースエンジニアリング、逆コンパイルまたは逆アセンブルを行い、またはその他の方法でソースコードを解明する作業等を行ってはなりません。
第２章 本サービスの導入と終了
第4条（本サービスの導入開始）
１．利用者は、利用者ご自身の責任で、ご自身が所有する（または、利用者が未成年者である場合は、利用者に対して使用を許諾した親権者等の法定代理人が所有する）デバイスに本アプリをダウンロードすることで本サービスの導入を開始します。
２．利用者が未成年者である場合、利用者は、親権者等の法定代理人の同意を得て、本サービスを利用するものとします。
３．利用者は、第三者に利用させる目的で本アプリを導入してはなりません。
４．利用者は、第三者の名義で本サービスを導入してはいけません。
第5条（本サービスの導入の取消し）
利用者が以下に該当する場合、当社は本サービスの導入の取消しを行う場合があります。
（１）利用者が実在しない場合。
（２）本規約の違反等により、本サービスの利用停止を現に受け、または過去に受けたことがある場合。
（３）利用者が未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、保佐人もしくは補助人の同意を得ていなかった場合。
（４）利用者及び利用者の関係者が、暴力団、暴力団関係企業、総会屋もしくはこれに準じる者またはその構成員（以下、総称して「反社会的勢力」といいます。）である場合。
（５）第14条（禁止事項）に該当する場合または、その恐れがある場合。
第6条（貸与及び譲渡の禁止）
１．利用者は、本アプリを第三者に利用させてはいけません。
２．前項により、利用者または当社及び第三者が被る損害については、当社に故意または重大な過失がある場合を除き、利用者の責任とします。
第7条（利用料金）
本アプリ及び本サービスは無料です。
第8条（通信販売）
本サービス内で、通信販売を行なっています。販売代金、販売に関する取引条件及び規定の詳細は、各商品ページ及び「特定商取引法に基づく表記」に従います。
第9条（利用契約の終了）
１．利用者は、いつでも、本アプリを利用者のデバイスより削除することにより、本アプリの利用を中止し、利用契約を終了することができます。
２．前項のほか、当社が、本サービスの提供を中止したとき、または、当社が、利用者による本サービスの利用の停止をしたとき（第18条）、利用契約は終了します。
第10条（契約終了後の本規約の効力）
利用者は、利用契約終了後も、本規約に違反する行為をしてはならないものとします。
第３章 利用者の義務と責任
第11条（利用者による設備及び通信料などの費用負担）
１．本サービスは、インターネットを利用したシステムで運用をしており、利用者の責任で必要なデバイス、通信手段等準備するものとします。
２．インターネット接続にかかわる通信業者契約料・電話料金や、デバイスからアクセスする際のパケット通信料等の費用は利用者が負担するものとします。
第12条（パスワードの管理責任）
１．本アプリは、利用者の識別及びサービスの提供の為、利用者のアカウントに対し、利用者ご自身でパスワードの設定をして頂きます。設定されたパスワードは、利用者が適切に取り扱い、機密保持に関しては、利用者が責任を負うものとします。
２．利用者のアカウントによりなされた本サービスの利用は、当社に重大な過失があることに起因する第三者のなりすましの場合を除き、すべて、利用者によって行なわれたものとみなされ、利用者は、故意、過失を問わず、利用料その他の一切の責任を負担するものとします。
第13条（著作権、商標権、特許権等の知的財産権について）
１．本アプリ内のプログラム、文章、写真、イラスト、動画、レイアウト、デザイン、その他のすべての情報（以下「コンテンツ」といいます。）の知的財産権は当社または正当な権限を有する第三者に帰属しております。
２．本アプリ内の商標（名称、記号、ロゴ、マーク等）は、当社または正当な権限を有する第三者に帰属しますので、無断で使用することはできません。
３．当社は、本サービスにおいて、利用者が情報を投稿できる仕組みを提供することがあります。この場合、利用者は、自身の一切の責任で情報を投稿するものとし、第三者の知的財産権、肖像権、プライバシー権その他一切の第三者の権利を侵害してはなりません。
４．前３項に関して、利用者と他の利用者または第三者との間に生じたトラブル等について、利用者は自己の責任にて解決するものとし、当社は一切の関与をしないものとし、損害等について一切の責任を負いません。
５．利用者は、利用者の本サービスの利用に関するデータを本サービス内においてのみ利用することができ、これらの所有権または知的財産権を取得することはありません。
第14条（禁止事項）
利用者は、本サービスを利用するにあたって以下の行為を行わないものとします。
（１）他の利用者、第三者または当社の権利、利益、名誉等を損ねること、誹謗、中傷及び侮辱する行為。またはそれらのおそれのある行為。
（２）他の利用者、第三者または当社に対する嫌がらせ及びハラスメント行為。
（３）他の利用者、実在及び架空の第三者または当社になりすます行為。
（４）当サイト内の情報または他者の情報を改ざん、消去する行為。
（５）本サービスの内容を無断で公開する行為、またはそのおそれのある行為。
（６）虚偽の情報や嫌がらせ目的などによる情報を投稿する行為。
（７）本人の同意を得ることなく、または詐欺的な手段により他者の個人情報を取得する行為。
（８）過度に複数のデバイスに本アプリを導入し、またはその他の方法により過度に複数のIDの付与を受ける行為。
（９）当社または第三者が管理するサーバー等の設備の維持運営を妨げる行為。
（10）有害なコンピュータプログラム等を送信し、または他者がそれらを受信可能な状態におく行為。
（11）サーバー等のアクセス制御機能を解除または回避するための情報、機器、ソフトウェア等を流通させる行為。
（12）本アプリまたはサーバー内のプログラムのバグその他の不具合を不当に利用する行為、またはそのほう助行為。
（13）選挙の事前運動、選挙運動（これらに類似する行為を含みます。）及び公職選挙法に抵触する行為。
（14）他者に対し、無断で、広告・宣伝・勧誘等の電子メールまたは嫌悪感を抱く電子メールを送信する行為。他者のメール受信を妨害する行為。
（15）電話、ファックス、メールアドレス、ホームページアドレスなど、いかなる方法にもかかわらず、取引を誘引すること及び実際に直接取引を行うこと。
（16）違法な薬物、銃器、毒物もしくは爆発物等の禁制品の製造、販売もしくは入手に係る情報を送信または表示する行為。賭博、業務妨害等の犯罪の手段として利用する行為。犯罪を助長し、または誘発するおそれのある情報を送信または表示する行為。
（17）わいせつ、児童ポルノもしくは児童虐待に相当する画像、映像、音声もしくは文書等を送信または表示する行為、またはこれらを収録した媒体を販売する行為、またはその送信、表示、販売を想起させる広告を表示または送信する行為。
（18）無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為。
（19）インターネット異性紹介事業を利用して児童を誘引する行為の規制等に関する法律に基づく、当該事業の提供者に対する規制及び当該事業を利用した不正勧誘行為の禁止に違反する行為。
（20）面識のない異性または同性との出会いや交際等を希望し、もしくは交際等に誘導する行為、または交際の相手方となるように誘う行為。
（21）自殺もしくは自傷に結び付く行為、またはこれを教唆もしくはほう助する行為。
（22）青少年の家出を誘引ないし助成する行為。
（23）上記各号の他、法令、または本規約に違反する行為。公序良俗に違反する行為。
（24）本アプリ及びコンテンツ内に、不適正な画像または動画を投稿する行為。
（25）本アプリに類似するサービス、またはアプリを開発する行為。
（26）その他当社が不適切と判断する行為。
第15条（秘密保持）
利用者は、本サービスに関連して当社が利用者に対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
第４章 運用・保守
第16条 （広告の掲載）
当社は、本アプリ上に任意の広告を掲載することができるものとします。
第17条（情報の利用）
当社は、利用者の氏名及び個人情報を公開しないことを条件に、利用者の投稿した情報を修正、変更等を含め、自由に利用することができるものとし、利用者は、当該情報の改変や公表の有無等について、著作権人格権を行使せず、異議を申し立てないことを承諾するものとします。
第18条（利用の停止）
当社は、利用者が以下のいずれかに該当する場合には、当該利用者に何らの通告をすることなく、利用者による本サービスの利用を直ちに停止できるものとします。
（１）利用者が、第5条（本サービスの導入の取消し）に該当した場合。
（２）利用者が第14条（禁止事項）各号に定める禁止行為を行った場合または行うおそれのある場合。
（３）本サービスの提供に支障があると、当社が判断した場合。
（４）利用者が死亡した場合、実在しなくなった場合、あるいは権利能力を失った場合。
（５）利用者が、本規約に違反した場合、または違反するおそれがある場合。
（６）当社が定める一定期間、利用者による本アプリの利用がなかった場合。
（７）その他当社が利用者として不適切と判断した場合。
第19条（免責）
１．当社は、本サービスで提供する情報について正確性を確保するように努めておりますが、その完全性、正確性、確実性、有用性、安全性、特定目的への適合性等について、何ら保証するものではありません。
２．当社は、本サービスがいかなる通信環境及び個々のデバイスにおいても動作することを保証するものではありません。
３．当サイト上に、外部のウェブサイトなどへのリンクをしている場合があります。リンク先のウェブサイトは当社が管理するものではありません。そのウェブサイトの掲載情報の完全性、正確性、確実性、有用性、安全性、特定目的への適合性等について、何ら保証するものではありません。
４．当社は、天候等の不可抗力に起因する場合及び通信会社やサーバー管理会社の不具合当、理由の如何を問わず、本サービスの提供の遅延、中断、停止、終了、利用不能または変更、利用者が本サービスに送信したメッセージまたは情報の削除または消失、利用者登録データの抹消、本サービスの利用による登録データの消失または機器の故障もしくは損傷、その他本サービスに関して利用者が被った損害について、賠償する責任を負わないものとします。
５．当社は、本サービスの利用に関連して利用者と他の利用者または第三者との間に生じた取引、連絡、紛争等については、当社に故意または重過失がある場合を除き、一切の責任を負いません。
６．当社は、コンピュータウィルスの侵入その他の第三者の行為により利用者に生じた損害について、当社に故意または重過失がある場合を除き、一切の責任を負いません。
第20条（損害賠償）
利用者が本規約に違反した場合、またはその他利用者の責任により当社に損害を与えた場合、当社は利用者に損害賠償を求めることができるものとします。現に損害が発生し、またはその恐れがある場合、当社は利用者に対し、その原因となる行為の差止め請求をすることができます。
第21条（通知、連絡）
本サービスに関する問い合わせ、その他利用者から当社に対する連絡または通知、及び当社から利用者に対する連絡または通知は、当社の定める方法にて行うものとします。
第５章 個人情報保護
第22条（個人情報の収集）
１．当社は、適正かつ公正な手段によって、個人情報を収集します。
２．インターネット経由で取得した個人情報はSSLにて暗号化され、サーバーに送信されます。
３．機微な個人情報（センシティブ情報）については、当社の業務の遂行上必要な範囲外のものを取得しません。
４．当社が個人情報を収集するときは、以下の目的の範囲内で、業務の遂行上必要な限りにおいて利用します。
（１）利用者に対する情報の提供、利用者情報の管理及び、依頼、問い合わせへの対応、通信販売などの業務の遂行。
（２）本サービスに関する営業上のご案内。
（３）本サービスの企画開発あるいは利用者満足度向上のためのアンケート調査等。
（４）法令による定めまたは行政当局の通達・指導などに基づく対応。
上記の利用目的は、相当の関連性を有すると合理的に認められる範囲内で変更することがあります。上記の利用目的を変更する場合には、その内容を当社のホームページ等により、公表します。
第23条（個人情報の第三者提供）
当社は、法令に定める場合等、正当な理由のない限り個人情報を本人の同意を得ることなく、第三者に提供しません。 ただし、以下の場合は利用者のご同意なく個人データを開示提供する場合があります。
（１）法令に基づく場合。
（２）人の生命、身体または財産〈法人の財産を含む〉の保護のために必要がある場合であって、 利用者のご同意を得ることが困難であるとき。
（３）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、 利用者のご同意を得ることが困難であるとき。
（４）国の機関もしくは地方公共団体またはその委託を受けた者が法令に定める事務を遂行することに対して協力する必要がある場合であって、利用者のご同意を得ることにより当該事務遂行に支障を及ぼす恐れがあるとき。
（５）通信販売の業務を遂行する為、販売元及び業務委託先へ情報を提供するとき。
（６）本アプリの開発・改修・保守・運営、並びに本サービスの提供、維持・運営を行うにあたり、必要な範囲内において、秘密保持契約を締結した開発元・業務委託先などへ情報を提供するとき。
第６章 その他
第24条（分離可能性）
本規約のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
第25条（準拠法及び管轄）
本規約の成立、効力、履行及び解釈に関しては、日本国法に準拠し、 本サービスに関連して、利用者と当社との間で問題が生じた場合には、誠意をもって協議の上、解決を図るものとします。協議しても解決せず、訴訟の必要が生じた場合には、当社の本店所在地を管轄する簡易裁判所または地方裁判所を第１審の専属的合意管轄裁判所とします。
（附則） 2018年3月1日施行
    </pre>
  </div>
</template>
