import { Auth } from "aws-amplify";
import axios from "axios";

interface BranchJson {
  code: string;
  name: string;
}

export default class Branch {
  code: string;
  name: string;

  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }

  static async search(bankcode: string, keyword: string): Promise<Branch[]> {
    console.log(`Branch.search(${keyword})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        apikey: process.env.VUE_APP_BANKCODEJP_API_KEY,
        filter: `(code==*${keyword}*,name==*${keyword}*,hiragana==*${keyword}*)`,
        limit: 10,
      },
    };

    const response = await axios.get(
      `https://apis.bankcode-jp.com/v1/banks/${bankcode}/branches`,
      config
    );

    const list: Branch[] = [];
    response.data.data.forEach((json: BranchJson) => {
      list.push(new Branch(json.code, json.name));
    });

    return list;
  }
}
