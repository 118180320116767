import { Auth } from "aws-amplify";
import axios from "axios";

interface SalesJson {
  ym: number;
  total_tax: number;
  total_shipping_cost: number;
  total_price: number;
  created_at: string;
  updated_at: string;
}

export default class Sales {
  ym: number;
  total_tax: number;
  total_shipping_cost: number;
  total_price: number;
  created_at: Date;
  updated_at: Date;

  constructor(
    ym: number,
    total_tax: number,
    total_shipping_cost: number,
    total_price: number,
    created_at: Date,
    updated_at: Date
  ) {
    this.ym = ym;
    this.total_tax = total_tax;
    this.total_shipping_cost = total_shipping_cost;
    this.total_price = total_price;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

  static async get(ym: number): Promise<Sales> {
    console.log(`Sales.get(${ym})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/sales/${ym}`,
      config
    );

    const json = response.data.data as SalesJson;
    console.log(json);

    return new Sales(
      json.ym,
      json.total_tax,
      json.total_shipping_cost,
      json.total_price,
      new Date(json.created_at),
      new Date(json.updated_at)
    );
  }
}
