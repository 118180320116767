import { Auth } from "aws-amplify";
import axios from "axios";

import Bank from "@/models/Bank";
import Branch from "@/models/Branch";

interface StaffJson {
  id: string;
  salon_id: string;
  salon_name: string;
  shop_id: string;
  shop_name: string;
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
  email: string;
  introduce: string | null;
  main_image_url: string | null;
  selfy_image_url: string | null;
  user_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface StaffSearchResult {
  staffs: Staff[];
  last_evaluated_key: string | null;
}

export default class Staff {
  id: string;
  salon_id: string;
  salon_name: string;
  shop_id: string;
  shop_name: string;
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
  email: string;
  introduce: string | null;
  main_image_url: string | null;
  selfy_image_url: string | null;
  user_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  get name(): string {
    return `${this.family_name} ${this.given_name}`;
  }

  get kana(): string {
    return `${this.family_name_kana} ${this.given_name_kana}`;
  }

  constructor(
    id: string,
    salon_id: string,
    salon_name: string,
    shop_id: string,
    shop_name: string,
    family_name: string,
    given_name: string,
    family_name_kana: string,
    given_name_kana: string,
    email: string,
    introduce: string | null,
    main_image_url: string | null,
    selfy_image_url: string | null,
    user_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.salon_id = salon_id;
    this.salon_name = salon_name;
    this.shop_id = shop_id;
    this.shop_name = shop_name;
    this.family_name = family_name;
    this.given_name = given_name;
    this.family_name_kana = family_name_kana;
    this.given_name_kana = given_name_kana;
    this.email = email;
    this.introduce = introduce;
    this.main_image_url = main_image_url;
    this.selfy_image_url = selfy_image_url;
    this.user_count = user_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Staff> {
    console.log(`Staff.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/staffs/${id}`,
      config
    );

    const json = response.data.data as StaffJson;

    return new Staff(
      json.id,
      json.salon_id,
      json.salon_name,
      json.shop_id,
      json.shop_name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.email,
      json.introduce,
      json.main_image_url,
      json.selfy_image_url,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async searchBySalonId(
    salon_id: string,
    keyword: string | null = null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<StaffSearchResult> {
    console.log(`Staff.search(${salon_id}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        salon_id,
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/staffs`,
      config
    );

    const list: Staff[] = [];
    response.data.data.forEach((json: StaffJson) => {
      list.push(
        new Staff(
          json.id,
          json.salon_id,
          json.salon_name,
          json.shop_id,
          json.shop_name,
          json.family_name,
          json.given_name,
          json.family_name_kana,
          json.given_name_kana,
          json.email,
          json.introduce,
          json.main_image_url,
          json.selfy_image_url,
          json.user_count,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      staffs: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    salon_id: string,
    shop_id: string,
    family_name: string,
    given_name: string,
    family_name_kana: string,
    given_name_kana: string,
    email: string
  ): Promise<Staff> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/staffs`,
      {
        salon_id: salon_id,
        shop_id: shop_id,
        family_name: family_name,
        given_name: given_name,
        family_name_kana: family_name_kana,
        given_name_kana: given_name_kana,
        email: email,
      },
      config
    );

    console.log(response);

    if (response.data.code !== "0") {
      const message = response.data.data as string;

      if (message == "the administrator email already exist") {
        throw "すでに登録されているメールアドレスです";
      }

      throw "スタッフの登録に失敗しました";
    }

    const json = response.data;

    return new Staff(
      json.id,
      json.salon_id,
      json.salon_name,
      json.shop_id,
      json.shop_name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.email,
      json.introduce,
      json.main_image_url,
      json.selfy_image_url,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    shop_id: string,
    family_name: string,
    given_name: string,
    family_name_kana: string,
    given_name_kana: string,
    introduce: string | null,
    main_image_url: string | null,
    selfy_image_url: string | null
  ): Promise<Staff> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/staffs/${id}`,
      {
        id,
        shop_id: shop_id,
        family_name,
        given_name,
        family_name_kana,
        given_name_kana,
        introduce,
        main_image_url,
        selfy_image_url,
      },
      config
    );

    console.log(response);

    const json = response.data;

    return new Staff(
      json.id,
      json.salon_id,
      json.salon_name,
      json.shop_id,
      json.shop_name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.email,
      json.introduce,
      json.main_image_url,
      json.selfy_image_url,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Staff> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/staffs/${id}`,
      config
    );

    console.log(response);

    const json = response.data;

    return new Staff(
      json.id,
      json.salon_id,
      json.salon_name,
      json.shop_id,
      json.shop_name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.email,
      json.introduce,
      json.main_image_url,
      json.selfy_image_url,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
