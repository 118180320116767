import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface ContestJson {
  id: string;
  main_image_url: string;
  title: string;
  theme: string;
  description: string;
  requirement: string;
  reward: string;
  publish_date: string;
  vote_start: string;
  vote_end: string;
  result_announcement_date: string;
  sponser_images: string[];
  view_count: number;
  click_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface ContestSearchResult {
  contests: Contest[];
  last_evaluated_key: string | null;
}

export default class Contest {
  id: string;
  main_image_url: string;
  title: string;
  theme: string;
  description: string;
  requirement: string;
  reward: string;
  publish_date: Date;
  vote_start: Date;
  vote_end: Date;
  result_announcement_date: Date;
  sponser_images: string[];
  view_count: number;
  click_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    main_image_url: string,
    title: string,
    theme: string,
    description: string,
    requirement: string,
    reward: string,
    publish_date: Date,
    vote_start: Date,
    vote_end: Date,
    result_announcement_date: Date,
    sponser_images: string[],
    view_count: number,
    click_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.main_image_url = main_image_url;
    this.title = title;
    this.theme = theme;
    this.description = description;
    this.requirement = requirement;
    this.reward = reward;
    this.publish_date = publish_date;
    this.vote_start = vote_start;
    this.vote_end = vote_end;
    this.result_announcement_date = result_announcement_date;
    this.sponser_images = sponser_images;
    this.view_count = view_count;
    this.click_count = click_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Contest> {
    console.log(`Contest.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/contests/${id}`,
      config
    );

    const json = response.data.data as ContestJson;

    return new Contest(
      json.id,
      json.main_image_url,
      json.title,
      json.theme,
      json.description,
      json.requirement,
      json.reward,
      new Date(json.publish_date),
      new Date(json.vote_start),
      new Date(json.vote_end),
      new Date(json.result_announcement_date),
      json.sponser_images,
      json.view_count,
      json.click_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<ContestSearchResult> {
    console.log(`Contest.search(${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/contests`,
      config
    );

    const list: Contest[] = [];
    response.data.data.forEach((json: ContestJson) => {
      list.push(
        new Contest(
          json.id,
          json.main_image_url,
          json.title,
          json.theme,
          json.description,
          json.requirement,
          json.reward,
          new Date(json.publish_date),
          new Date(json.vote_start),
          new Date(json.vote_end),
          new Date(json.result_announcement_date),
          json.sponser_images,
          json.view_count,
          json.click_count,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      contests: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    title: string,
    main_image_url: string,
    theme: string,
    description: string,
    requirement: string,
    reward: string,
    publish_date: Date,
    vote_start: Date,
    vote_end: Date,
    result_announcement_date: Date,
    sponser_images: string[]
  ): Promise<Contest> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/contests`,
      {
        title,
        main_image_url,
        theme,
        description,
        requirement,
        reward,
        publish_date: moment(publish_date).format("YYYY-MM-DD"),
        vote_start: moment(vote_start).format("YYYY-MM-DD"),
        vote_end: moment(vote_end).format("YYYY-MM-DD"),
        result_announcement_date: moment(result_announcement_date).format(
          "YYYY-MM-DD"
        ),
        sponser_images,
      },
      config
    );

    console.log(response);

    return new Contest(
      response.data.id,
      response.data.main_image_url,
      response.data.title,
      response.data.theme,
      response.data.description,
      response.data.requirement,
      response.data.reward,
      new Date(response.data.publish_date),
      new Date(response.data.vote_start),
      new Date(response.data.vote_end),
      new Date(response.data.result_announcement_date),
      response.data.sponser_images,
      response.data.view_count,
      response.data.click_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async update(
    id: string,
    title: string,
    main_image_url: string,
    theme: string,
    description: string,
    requirement: string,
    reward: string,
    publish_date: Date,
    vote_start: Date,
    vote_end: Date,
    result_announcement_date: Date,
    sponser_images: string[]
  ): Promise<Contest> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/contests/${id}`,
      {
        id,
        title,
        main_image_url,
        theme,
        description,
        requirement,
        reward,
        publish_date: moment(publish_date).format("YYYY-MM-DD"),
        vote_start: moment(vote_start).format("YYYY-MM-DD"),
        vote_end: moment(vote_end).format("YYYY-MM-DD"),
        result_announcement_date: moment(result_announcement_date).format(
          "YYYY-MM-DD"
        ),
        sponser_images,
      },
      config
    );

    console.log(response);

    return new Contest(
      response.data.id,
      response.data.main_image_url,
      response.data.title,
      response.data.theme,
      response.data.description,
      response.data.requirement,
      response.data.reward,
      new Date(response.data.publish_date),
      new Date(response.data.vote_start),
      new Date(response.data.vote_end),
      new Date(response.data.result_announcement_date),
      response.data.sponser_images,
      response.data.view_count,
      response.data.click_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Contest> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/contests/${id}`,
      config
    );

    console.log(response);

    return new Contest(
      response.data.id,
      response.data.main_image_url,
      response.data.title,
      response.data.theme,
      response.data.description,
      response.data.requirement,
      response.data.reward,
      new Date(response.data.publish_date),
      new Date(response.data.vote_start),
      new Date(response.data.vote_end),
      new Date(response.data.result_announcement_date),
      response.data.sponser_images,
      response.data.view_count,
      response.data.click_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }
}
