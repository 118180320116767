
import { defineComponent } from "vue";

export default defineComponent({
  name: "SalonHeader",

  props: {
    is_sidebar_open: Boolean,
  },

  watch: {
    is_sidebar_open: {
      handler() {
        console.log("b");
      },
    },
  },

  methods: {
    toggleSidebarOpen() {
      this.$emit("togglesidebar");
    },
  },
});
