import { Auth } from "aws-amplify";
import axios from "axios";

import Bank from "@/models/Bank";
import Branch from "@/models/Branch";

interface DesignJson {
  id: string;
  salon_id: string;
  salon_name: string;
  staff_id: string;
  staff_name: string;
  staff_selfy_image_url: string;
  medias: any;
  blocking: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface DesignSearchResult {
  designs: Design[];
  last_evaluated_key: string | null;
}

export default class Design {
  id: string;
  salon_id: string;
  salon_name: string;
  staff_id: string;
  staff_name: string;
  staff_selfy_image_url: string;
  medias: any;
  blocking: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    salon_id: string,
    salon_name: string,
    staff_id: string,
    staff_name: string,
    staff_selfy_image_url: string,
    medias: any,
    blocking: boolean,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.salon_id = salon_id;
    this.salon_name = salon_name;
    this.staff_id = staff_id;
    this.staff_name = staff_name;
    this.staff_selfy_image_url = staff_selfy_image_url;
    this.medias = medias;
    this.blocking = blocking;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Design> {
    console.log(`Design.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/designs/${id}`,
      config
    );

    const json = response.data.data as DesignJson;

    return new Design(
      json.id,
      json.salon_id,
      json.salon_name,
      json.staff_id,
      json.staff_name,
      json.staff_selfy_image_url,
      json.medias,
      json.blocking,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<DesignSearchResult> {
    console.log(`Design.search(${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        include_blocking: "true",
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/designs`,
      config
    );

    const list: Design[] = [];
    response.data.data.forEach((json: DesignJson) => {
      list.push(
        new Design(
          json.id,
          json.salon_id,
          json.salon_name,
          json.staff_id,
          json.staff_name,
          json.staff_selfy_image_url,
          json.medias,
          json.blocking,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      designs: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async block(id: string): Promise<Design> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/designs/${id}`,
      {
        "design_id": id,
        "type": "block"
      },
      config
    );

    console.log(response);

    const json = response.data.data as DesignJson;

    return new Design(
      json.id,
      json.salon_id,
      json.salon_name,
      json.staff_id,
      json.staff_name,
      json.staff_selfy_image_url,
      json.medias,
      json.blocking,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }


  static async unblock(id: string): Promise<Design> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/designs/${id}`,
      {
        "design_id": id,
        "type": "unblock"
      },
      config
    );

    console.log(response);

    const json = response.data.data as DesignJson;

    return new Design(
      json.id,
      json.salon_id,
      json.salon_name,
      json.staff_id,
      json.staff_name,
      json.staff_selfy_image_url,
      json.medias,
      json.blocking,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
