import { Auth } from "aws-amplify";
import axios from "axios";
import ApiResponse from "@/models/ApiResponse";

interface DealerJson {
  id: string;
  name: string;
  kana: string;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
  telephone: string;
  email: string;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: string;
  account_no: string;
  account_name: string;
  shop_count: number;
  staff_count: number;
  user_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface DealerSearchResult {
  dealers: Dealer[];
  last_evaluated_key: string | null;
}

export default class Dealer {
  id: string;
  name: string;
  kana: string;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
  telephone: string;
  email: string;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: string;
  account_no: string;
  account_name: string;
  shop_count: number;
  staff_count: number;
  user_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    id: string,
    name: string,
    kana: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    email: string,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string,
    shop_count: number,
    staff_count: number,
    user_count: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.name = name;
    this.kana = kana;
    this.postcode = postcode;
    this.prefecture = prefecture;
    this.city = city;
    this.address = address;
    this.building = building;
    this.telephone = telephone;
    this.email = email;
    this.bank_code = bank_code;
    this.bank_name = bank_name;
    this.branch_code = branch_code;
    this.branch_name = branch_name;
    this.account_type = account_type;
    this.account_no = account_no;
    this.account_name = account_name;
    this.shop_count = shop_count;
    this.staff_count = staff_count;
    this.user_count = user_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Dealer> {
    console.log(`Dealer.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/dealers/${id}`,
      config
    );

    const json = response.data.data as DealerJson;

    return new Dealer(
      json.id,
      json.name,
      json.kana,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.email,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.shop_count,
      json.staff_count,
      json.user_count,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<DealerSearchResult> {
    console.log(`Dealer.search(${keyword}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/dealers`,
      config
    );

    const list: Dealer[] = [];
    response.data.data.forEach((json: DealerJson) => {
      list.push(
        new Dealer(
          json.id,
          json.name,
          json.kana,
          json.postcode,
          json.prefecture,
          json.city,
          json.address,
          json.building,
          json.telephone,
          json.email,
          json.bank_code,
          json.bank_name,
          json.branch_code,
          json.branch_name,
          json.account_type,
          json.account_no,
          json.account_name,
          json.shop_count,
          json.staff_count,
          json.user_count,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      dealers: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    dealer_name: string,
    dealer_kana: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    email: string,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string,
    administrator_name: string,
    administrator_kana: string,
    administrator_email: string
  ): Promise<Dealer> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/dealers`,
      {
        name: dealer_name,
        kana: dealer_kana,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
        email: email,
        bank_code: bank_code,
        bank_name: bank_name,
        branch_code: branch_code,
        branch_name: branch_name,
        account_type: account_type,
        account_no: account_no,
        account_name: account_name,
        administrator_name: administrator_name,
        administrator_kana: administrator_kana,
        administrator_email: administrator_email,
      },
      config
    );

    console.log(response);

    if (response.data.code !== "0") {
      const message = response.data.data as string;

      if (message == "the administrator email already exist") {
        throw "すでに登録されているメールアドレスです";
      }

      throw "ディラーの登録に失敗しました";
    }

    return new Dealer(
      response.data.id,
      response.data.name,
      response.data.kana,
      response.data.postcode,
      response.data.prefecture,
      response.data.city,
      response.data.address,
      response.data.building,
      response.data.telephone,
      response.data.email,
      response.data.bank_code,
      response.data.bank_name,
      response.data.branch_code,
      response.data.branch_name,
      response.data.account_type,
      response.data.account_no,
      response.data.account_name,
      response.data.shop_count,
      response.data.staff_count,
      response.data.user_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async update(
    id: string,
    name: string,
    kana: string,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string,
    telephone: string,
    email: string,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string
  ): Promise<Dealer> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/dealers/${id}`,
      {
        id,
        name,
        kana,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
        email: email,
        bank_code: bank_code,
        bank_name: bank_name,
        branch_code: branch_code,
        branch_name: branch_name,
        account_type: account_type,
        account_no: account_no,
        account_name: account_name,
      },
      config
    );

    console.log(response);

    return new Dealer(
      response.data.id,
      response.data.name,
      response.data.kana,
      response.data.postcode,
      response.data.prefecture,
      response.data.city,
      response.data.address,
      response.data.building,
      response.data.telephone,
      response.data.email,
      response.data.bank_code,
      response.data.bank_name,
      response.data.branch_code,
      response.data.branch_name,
      response.data.account_type,
      response.data.account_no,
      response.data.account_name,
      response.data.shop_count,
      response.data.staff_count,
      response.data.user_count,
      new Date(response.data.created_at),
      new Date(response.data.updated_at),
      response.data.deleted_at ? new Date(response.data.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<ApiResponse<Dealer>> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/dealers/${id}`,
      config
    );

    console.log(response);

    if (response.data.code !== "0") {
      return new ApiResponse<Dealer>(false, response.data.message, null);
    }

    return new ApiResponse<Dealer>(
      true,
      null,
      new Dealer(
        response.data.id,
        response.data.name,
        response.data.kana,
        response.data.postcode,
        response.data.prefecture,
        response.data.city,
        response.data.address,
        response.data.building,
        response.data.telephone,
        response.data.email,
        response.data.bank_code,
        response.data.bank_name,
        response.data.branch_code,
        response.data.branch_name,
        response.data.account_type,
        response.data.account_no,
        response.data.account_name,
        response.data.shop_count,
        response.data.staff_count,
        response.data.user_count,
        new Date(response.data.created_at),
        new Date(response.data.updated_at),
        response.data.deleted_at ? new Date(response.data.deleted_at) : null
      ));
  }
}
