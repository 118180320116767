import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface ContestEntryResourceJson {
  original_resource_url: string;
  resource_url: string;
  thumbnail_url: string;
}

interface ContestEntryJson {
  id: string;
  contest_id: string;
  staff_id: string;
  salon_name: string;
  staff_name: string;
  resource_urls: ContestEntryResourceJson[];
  title: string;
  description: string;
  order: number;
  vote_count: number;
  created_at: string;
}

interface ContestEntrySearchResult {
  entries: ContestEntry[];
  last_evaluated_key: string | null;
}

class ContestEntryResource {
  original_resource_url: string;
  resource_url: string;
  thumbnail_url: string;

  constructor(
    original_resource_url: string,
    resource_url: string,
    thumbnail_url: string
  ) {
    this.original_resource_url = original_resource_url;
    this.resource_url = resource_url;
    this.thumbnail_url = thumbnail_url;
  }
}

export default class ContestEntry {
  id: string;
  contest_id: string;
  staff_id: string;
  salon_name: string;
  staff_name: string;
  resource_urls: ContestEntryResourceJson[];
  title: string;
  description: string;
  order: number;
  vote_count: number;
  created_at: Date | null;

  constructor(
    id: string,
    contest_id: string,
    staff_id: string,
    salon_name: string,
    staff_name: string,
    resource_urls: ContestEntryResource[],
    title: string,
    description: string,
    order: number,
    vote_count: number,
    created_at: Date
  ) {
    this.id = id;
    this.contest_id = contest_id;
    this.staff_id = staff_id;
    this.salon_name = salon_name;
    this.staff_name = staff_name;
    this.resource_urls = resource_urls;
    this.title = title;
    this.description = description;
    this.order = order;
    this.vote_count = vote_count;
    this.created_at = created_at;
  }

  static async search(
    contestId: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<ContestEntrySearchResult> {
    console.log(`ContestEntry.search(${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        contest_id: contestId,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/contestentries`,
      config
    );

    const list: ContestEntry[] = [];
    response.data.data.forEach((json: ContestEntryJson) => {
      list.push(
        new ContestEntry(
          json.id,
          json.contest_id,
          json.staff_id,
          json.salon_name,
          json.staff_name,
          json.resource_urls,
          json.title,
          json.description,
          json.order,
          json.vote_count,
          new Date(json.created_at)
        )
      );
    });

    return {
      entries: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }
}
