
import { defineComponent, Ref } from "vue";
import { FieldContext, useField, useForm } from "vee-validate";
import * as yup from "yup";
import { Storage } from "aws-amplify";
import { uuid } from "vue-uuid";
import moment from "moment";
import Editor from "@tinymce/tinymce-vue";

import Salon from "@/models/Salon";
import Trial from "@/models/Trial";

interface TrialEditState {
  // is_loading: boolean;

  trial: Trial | null;

  salons: Salon[];

  title_field: FieldContext<string>;
  title: Ref<string>;
  title_error: Ref<string>;

  salon_ids: string[];
  salon_ids_error: string | null;

  main_image_url_field: FieldContext<string>;
  main_image_url: Ref<string>;
  main_image_url_error: Ref<string>;

  publish_from_field: FieldContext<string>;
  publish_from: Ref<string>;
  publish_from_error: Ref<string>;

  publish_to_field: FieldContext<string>;
  publish_to: Ref<string>;
  publish_to_error: Ref<string>;

  receivable: boolean;

  body_field: FieldContext<string>;
  body: Ref<string>;
  body_error: Ref<string>;

  mceCallback: (uri: string, option: any) => void;
}

export default defineComponent({
  components: {
    Editor,
  },

  data() {
    const formSchema = yup.object({
      title: yup.string().required("必須項目です"),
      main_image_url: yup.string().required("必須項目です"),
      publish_from: yup.string(),
      publish_to: yup.string(),
      body: yup.string().required("必須項目です"),
    });

    useForm({ validationSchema: formSchema });

    const title = useField<string>("title");
    const main_image_url = useField<string>("main_image_url");
    const publish_from = useField<string>("publish_from");
    const publish_to = useField<string>("publish_to");
    const body = useField<string>("body");

    return {
      // is_loading: false,

      trial: null,

      salons: [] as Salon[],

      title_field: title,
      title: title.value,
      title_error: title.errorMessage,

      // salon_ids: [] as string[],
      salon_ids_error: null,

      main_image_url_field: main_image_url,
      main_image_url: main_image_url.value,
      main_image_url_error: main_image_url.errorMessage,

      publish_from_field: publish_from,
      publish_from: publish_from.value,
      publish_from_error: publish_from.errorMessage,

      publish_to_field: publish_to,
      publish_to: publish_to.value,
      publish_to_error: publish_to.errorMessage,

      receivable: true,

      body_field: body,
      body: body.value,
      body_error: body.errorMessage,
    } as TrialEditState;
  },

  computed: {
    salon_ids(): string[] {
      const ids: string[] = [];

      this.salons.forEach((salon) => {
        if (salon.is_selected) {
          ids.push(salon.id);
        }
      });

      return ids;
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      const id = this.$route.params.trial_id as string | null;

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await Salon.search(null);
          this.salons = [];

          result.salons.forEach((salon) => {
            if (!salon.deleted_at) {
              this.salons.push(salon);
            }
          });

          if (id) {
            this.trial = await Trial.get(id);
            console.log(this.trial);

            this.title = this.trial.title;
            this.receivable = this.trial.receivable;

            this.trial.salon_ids.forEach((id) => {
              this.salons.forEach((salon) => {
                if (salon.id == id) {
                  salon.is_selected = true;
                }
              });
            });

            this.main_image_url = this.trial.main_image_url;

            if (this.trial.publish_from) {
              const date = this.trial.publish_from as Date;
              this.publish_from = moment(date).format("YYYY-MM-DD");
            }

            if (this.trial.publish_to) {
              const date = this.trial.publish_to as Date;
              this.publish_to = moment(date).format("YYYY-MM-DD");
            }
            this.body = this.trial.body;
          }
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onSalonSelected(salon: Salon) {
      salon.is_selected = !salon.is_selected;
      // if(this.salon_ids.indexOf(salon.id) >= 0) {
      //   this.salon_ids.splice(this.salon_ids.indexOf(salon.id), 1);
      // } else {
      //   this.salon_ids.push(salon.id);
      // }
      // this.salon_ids.length = 0;
      // Array.from(e.target.options).forEach((option) => {
      //   if (option.selected) {
      //     this.salon_ids.push(option.value);
      //   }
      // });
      // console.log(this.salon_ids);
    },

    onInputFileChanged(e: any) {
      console.log(e);

      const files = e.target.files as File[];
      if (files.length == 0) {
        return;
      }

      const file = files[0];

      this.upload(file);
    },

    onFilePicked(callback: (uri: string, option: any) => void) {
      this.mceCallback = callback;
      const uploadFile = this.$refs.uploadFile as HTMLInputElement;
      uploadFile.click();
    },

    onMainImageDropped(e: DragEvent) {
      console.log("onMainImageDropped");
      if (!e.dataTransfer) {
        return;
      }

      const file = e.dataTransfer.files[0];
      this.upload(file);
    },

    removeMainImage() {
      this.main_image_url = "";
    },

    upload(file: File) {
      const split = file.name.split(".");
      const filename = uuid.v4();
      const extension = split[split.length - 1];
      const contentType = extension == "png" ? "image/png" : "image/jpg";

      setTimeout(async () => {
        Storage.configure({ customPrefix: { public: "trials" } });
        const s3response = await Storage.put(
          `/${filename}.${extension}`,
          file,
          {
            contentType: contentType,
          }
        );

        console.log(s3response);

        this.main_image_url = `${process.env.VUE_APP_HOST}/trials/${filename}.${extension}`;
      });
    },

    async onUploadFileChanged() {
      const uploadFile = this.$refs.uploadFile as HTMLInputElement;

      if (!uploadFile.files) {
        return;
      }
      try {
        const split = uploadFile.files[0].name.split(".");
        const extension = split[split.length - 1];
        const filename = `${uuid.v4()}.${extension}`;
        Storage.configure({ customPrefix: { public: "trials" } });
        await Storage.put(`/${filename}`, uploadFile.files[0], {
          contentType: extension == "png" ? "image/png" : "image/jpeg",
        });

        uploadFile.value = "";

        if (this.mceCallback) {
          this.mceCallback(
            `${process.env.VUE_APP_HOST}/trials/${filename}`,
            {}
          );
        }
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    },

    async validate(): Promise<boolean> {
      this.salon_ids_error = null;

      await this.title_field.validate();
      await this.main_image_url_field.validate();
      await this.publish_from_field.validate();
      await this.publish_to_field.validate();
      await this.body_field.validate();

      if (this.salon_ids.length <= 0) {
        this.salon_ids_error = "サロンを選択してください。";
      }

      return (
        !this.title_error &&
        !this.main_image_url_error &&
        !this.publish_from_error &&
        !this.publish_to_error &&
        !this.body_error &&
        !this.salon_ids_error
      );
    },

    onSave() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          console.log(this.publish_from);

          const valid = await this.validate();
          if (!valid) {
            return;
          }

          if (!this.trial) {
            await Trial.register(
              this.salon_ids,
              this.title,
              this.main_image_url!,
              this.body,
              this.publish_from ? moment(this.publish_from).toDate() : null,
              this.publish_to ? moment(this.publish_to).toDate() : null,
              this.receivable
            );
          } else {
            await Trial.update(
              this.trial.id,
              this.salon_ids,
              this.title,
              this.main_image_url!,
              this.body,
              this.publish_from ? moment(this.publish_from).toDate() : null,
              this.publish_to ? moment(this.publish_to).toDate() : null,
              this.receivable
            );
          }

          this.$router.push(`/trials`);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
      console.log(this);
    },
  },
});
