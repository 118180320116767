import { createI18n } from 'vue-i18n';

export const i18n = createI18n({
  legacy: false,
  locale: "jp",
  fallbackLocale: "jp",
  messages: {
    jp: {
      "exist related salon": "代理店登録しているサロンがあるので、削除できません。",
    },
  },
});