import { Auth } from "aws-amplify";
import axios from "axios";

interface TrialApplymentJson {
  id: string;
  customer_id: string;
  customer_name: string;
  customer_email: string;
  created_at: string;
}

interface TrialApplymentSearchResult {
  applyments: TrialApplyment[];
  last_evaluated_key: string | null;
}

export default class TrialApplyment {
  id: string;
  customer_id: string;
  customer_name: string;
  customer_email: string;
  created_at: Date;

  constructor(
    id: string,
    customer_id: string,
    customer_name: string,
    customer_email: string,
    created_at: Date
  ) {
    this.id = id;
    this.customer_id = customer_id;
    this.customer_name = customer_name;
    this.customer_email = customer_email;
    this.created_at = created_at;
  }

  static async search(
    trial_id: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<TrialApplymentSearchResult> {
    console.log(`TrialApplyment.searchTrend(${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        trial_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/trialapplyments`,
      config
    );

    const list: TrialApplyment[] = [];
    response.data.data.forEach((json: TrialApplymentJson) => {
      list.push(
        new TrialApplyment(
          json.id,
          json.customer_id,
          json.customer_name,
          json.customer_email,
          new Date(json.created_at)
        )
      );
    });

    return {
      applyments: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }
}
