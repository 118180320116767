
import { defineComponent, Ref } from "vue";
import { FieldContext, useField, useForm } from "vee-validate";
import * as yup from "yup";

import { PREFECTURES } from "@/utils/prefectures";
import Salon from "@/models/Salon";
import Shop from "@/models/Shop";
import Prefecture from "@/models/Prefecture";
import AddressUtil from "@/utils/AddressUtil";

interface ShopEditState {
  // is_loading: boolean;

  salon: Salon;
  shop: Shop | null;

  name_field: FieldContext<string>;
  name: Ref<string>;
  name_error: Ref<string>;

  kana_field: FieldContext<string>;
  kana: Ref<string>;
  kana_error: Ref<string>;

  postcode_field: FieldContext<string>;
  postcode: Ref<string>;
  postcode_error: Ref<string>;

  prefectures: string[];

  prefecture_field: FieldContext<string>;
  prefecture: Ref<string>;
  prefecture_error: Ref<string>;

  city_field: FieldContext<string>;
  city: Ref<string>;
  city_error: Ref<string>;

  address_field: FieldContext<string>;
  address: Ref<string>;
  address_error: Ref<string>;

  building_field: FieldContext<string>;
  building: Ref<string>;
  building_error: Ref<string>;

  telephone_field: FieldContext<string>;
  telephone: Ref<string>;
  telephone_error: Ref<string>;

  message: string | null;
}

export default defineComponent({
  components: {
    // Autocomplete,
  },

  data() {
    const formSchema = yup.object({
      name: yup.string().required("必須項目です"),
      kana: yup.string().kana().required("必須項目です"),
      postcode: yup.string().postcode().required("必須項目です"),
      prefecture: yup.string().required("必須項目です"),
      city: yup.string().required("必須項目です"),
      address: yup.string().required("必須項目です"),
      building: yup.string(),
      telephone: yup.string().phonenumber().required("必須項目です"),
    });

    useForm({ validationSchema: formSchema });

    const name = useField<string>("name");
    const kana = useField<string>("kana");
    const postcode = useField<string>("postcode");
    const prefecture = useField<string>("prefecture");
    const city = useField<string>("city");
    const address = useField<string>("address");
    const building = useField<string>("building");
    const telephone = useField<string>("telephone");

    return {
      // is_loading: false,

      prefectures: PREFECTURES,

      salon: {} as Salon,
      shop: null,

      name_field: name,
      name: name.value,
      name_error: name.errorMessage,

      kana_field: kana,
      kana: kana.value,
      kana_error: kana.errorMessage,

      postcode_field: postcode,
      postcode: postcode.value,
      postcode_error: postcode.errorMessage,

      prefecture_field: prefecture,
      prefecture: prefecture.value,
      prefecture_error: prefecture.errorMessage,

      city_field: city,
      city: city.value,
      city_error: city.errorMessage,

      address_field: address,
      address: address.value,
      address_error: address.errorMessage,

      building_field: building,
      building: building.value,
      building_error: building.errorMessage,

      telephone_field: telephone,
      telephone: telephone.value,
      telephone_error: telephone.errorMessage,

      message: null,
    } as ShopEditState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    refresh() {
      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const salonId = this.$route.params.salon_id as string;
          const id = this.$route.params.shop_id as string | null;

          this.salon = await Salon.get(salonId);

          if (id) {
            this.shop = await Shop.get(id);

            this.name = this.shop.name;
            this.kana = this.shop.kana;

            this.postcode = this.shop.postcode;
            this.prefecture = this.shop.prefecture;
            this.city = this.shop.city;
            this.address = this.shop.address;
            this.building = this.shop.building;
            this.telephone = this.shop.telephone;
          }
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    onPostcodeChanged() {
      console.log(`${this.postcode} ${this.postcode_error}`);
      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await AddressUtil.search(this.postcode);
          if (!result || result.length == 0) {
            return;
          }
          const data = result[0] as any;
          this.prefecture = Prefecture.all().find((p) => p == data.pref) || "";
          // if (this.prefecture) {
          //   this.prefecture_keyword = this.prefecture;
          // }
          this.city = data.city as string;
          this.address = data.town as string;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    async validate(): Promise<boolean> {
      await this.name_field.validate();
      await this.kana_field.validate();
      await this.postcode_field.validate();
      await this.prefecture_field.validate();
      await this.city_field.validate();
      await this.address_field.validate();
      await this.building_field.validate();
      await this.telephone_field.validate();

      return (
        !this.name_error &&
        !this.kana_error &&
        !this.postcode_error &&
        !this.prefecture_error &&
        !this.city_error &&
        !this.address_error &&
        !this.building_error &&
        !this.telephone_error
      );
    },

    onSave() {
      if (this.$store.state.is_loading) {
        return;
      }

      const salon_id = this.$route.params.salon_id.toString();

      this.message = null;

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const valid = await this.validate();
          if (!valid) {
            return;
          }

          if (!this.shop) {
            await Shop.register(
              salon_id,
              this.name,
              this.kana,
              this.postcode,
              this.prefecture,
              this.city,
              this.address,
              this.building,
              this.telephone
            );
          } else {
            await Shop.update(
              this.shop.id,
              this.name,
              this.kana,
              this.postcode,
              this.prefecture,
              this.city,
              this.address,
              this.building,
              this.telephone
            );
          }

          this.$router.push(`/salons/${salon_id}`);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
      console.log(this);
    },
  },
});
