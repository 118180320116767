import { createStore } from "vuex";
import { Auth } from "aws-amplify";
import { bool } from "aws-sdk/clients/signer";

export default createStore({
  state: {
    is_loading: false,
    is_logged_in: false,
    role: null,
    salon_id: null,
    is_sidebar_open: false,
  },

  mutations: {
    setIsLoading(state, value) {
      console.log(`setIsLoading(${value})`);
      state.is_loading = value;
    },

    setIsLoggedIn(state, value) {
      console.log(`setIsLoggedIn(${value})`);
      state.is_logged_in = value;
    },

    setRole(state, value) {
      console.log(`setRole(${value})`);
      state.role = value;
    },

    setSalonId(state, value) {
      console.log(`setSalonId(${value})`);
      state.salon_id = value;
    },

    toggleSidebarOpen(state) {
      console.log(`toggleSidebarOpen()`);
      state.is_sidebar_open = !state.is_sidebar_open;
      console.log(state.is_sidebar_open);
    },
  },

  actions: {
    async signIn({ commit }) {
      const cognitoUser = await Auth.currentUserInfo();
      commit("setIsLoggedIn", !!cognitoUser);
      commit(
        "setRole",
        cognitoUser ? cognitoUser.attributes["custom:role"] : null
      );
      commit(
        "setSalonId",
        cognitoUser ? cognitoUser.attributes["custom:salon"] : null
      );
    },

    async signOut({ commit }) {
      await Auth.signOut();
      commit("setIsLoggedIn", false);
      commit("setRole", null);
      commit("setSalonId", null);
    },

    async refreshUser({ commit }) {
      console.log("refreshUser");
      const cognitoUser = await Auth.currentUserInfo();
      console.log(cognitoUser);
      commit("setIsLoggedIn", !!cognitoUser);
      commit(
        "setRole",
        cognitoUser ? cognitoUser.attributes["custom:role"] : null
      );
      commit(
        "setSalonId",
        cognitoUser ? cognitoUser.attributes["custom:salon"] : null
      );
    },

    toggleSidebarOpen({ commit }) {
      commit("toggleSidebarOpen");
    },

    setLoading({ commit }, is_loading: bool) {
      commit("setIsLoading", is_loading);
    },
  },
});
