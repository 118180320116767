
import { defineComponent } from "vue";
import moment from "moment";
import Design from "@/models/Design";

interface DesignListState {
  // is_loading: boolean;
  keyword: string;
  designs: Design[];
  last_evaluated_key: string | null;
}

const PAGE_SIZE = 20;

export default defineComponent({
  data() {
    return {
      // is_loading: false,
      keyword: "",
      designs: [],
      last_evaluated_key: null,
    } as DesignListState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      console.log(`try to search (keyword=${this.keyword})`);

      if (this.$store.state.is_loading) {
        return;
      }

      this.$store.dispatch("setLoading", true);
      setTimeout(async () => {
        try {
          const result = await Design.search();
          this.designs = result.designs;
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    },

    toggleBlocking(design: Design) {
      this.$store.dispatch("setLoading", true);

      setTimeout(async () => {
        try {
          if(design.blocking) {
            await Design.unblock(design.id);
            design.blocking = false;
          } else {
            await Design.block(design.id);
            design.blocking = true;
          }
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      });
    }
  },
});
